import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { 
  Box, 
  Paper, 
  Typography, 
  CircularProgress, 
  Grid, 
  IconButton,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Alert,
  Card,
  CardContent,
  Chip,
  Tooltip,
  Switch,
  FormGroup,
  FormControlLabel,
  Checkbox,
  LinearProgress,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import {
  ZoomIn,
  ZoomOut,
  PanTool,
  Refresh,
  Layers,
  Close,
  Info,
  BrushOutlined,
  ColorLensOutlined,
  ViewInAr,
  FormatListBulleted,
  BarChart,
  RotateLeft,
  CropFree,
  Save
} from '@mui/icons-material';
import { Stage, Layer, Line, Circle, Group, Text, Ellipse, Arc, Shape, Rect } from 'react-konva';
import { debounce } from 'lodash';

// Импортируем кастомные компоненты
import ViewerToolbar from './ViewerToolbar';
import EntityInfoPanel from './EntityInfoPanel';

// Импортируем сервис API
import { fetchDrawing, updateEntityStatus, updateElementsStatus, fetchMetrics, generateReport } from '../services/api';
import { useAppContext } from '../context/AppContext';

const STATUS_COLORS = {
  not_started: '#808080', // Grey
  in_progress: '#FFA500', // Orange
  delayed: '#FF0000',     // Red
  completed: '#00FF00'    // Green
};

const STATUS_LABELS = {
  not_started: 'Not Started',
  in_progress: 'In Progress',
  delayed: 'Delayed',
  completed: 'Completed'
};

const DxfViewer = () => {
  const { fileId } = useParams();
  const { showNotification } = useAppContext();
  const [drawing, setDrawing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [viewportSize, setViewportSize] = useState({ width: 800, height: 600 });
  const [scale, setScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isPanning, setIsPanning] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [infoDrawerOpen, setInfoDrawerOpen] = useState(false);
  const [layersVisible, setLayersVisible] = useState({});
  const [visibleLayers, setVisibleLayers] = useState([]);
  const [metrics, setMetrics] = useState(null);
  const containerRef = useRef(null);
  const stageRef = useRef(null);
  
  // Состояния для режима выделения
  const [selectionMode, setSelectionMode] = useState(false);
  const [selectedElements, setSelectedElements] = useState([]);
  const [workStatus, setWorkStatus] = useState('completed');
  const [allowedElementTypes, setAllowedElementTypes] = useState(['LINE', 'POLYLINE', 'ARC', 'CIRCLE', 'SPLINE', 'ELLIPSE']);
  const [selectionToolDrawerOpen, setSelectionToolDrawerOpen] = useState(false);
  const [debug, setDebug] = useState(false);
  
  // Новые состояния для расширенных возможностей
  const [is3DMode, setIs3DMode] = useState(false);
  const [infoTabValue, setInfoTabValue] = useState(0);
  const [entityTypeVisibility, setEntityTypeVisibility] = useState({});
  const [filtering, setFiltering] = useState(false);
  const [filterByStatus, setFilterByStatus] = useState(null);
  
  // Оптимизированные функции
  const debouncedSetPosition = useCallback(debounce((newPos) => {
    setPosition(newPos);
  }, 5), []);

  const debouncedSetScale = useCallback(debounce((newScale) => {
    setScale(newScale);
  }, 5), []);

  // Fetch drawing data
  useEffect(() => {
    const loadDrawing = async () => {
      try {
        setLoading(true);
        const data = await fetchDrawing(fileId);
        console.log("Loaded drawing data:", data.entities?.length, "entities");
        setDrawing(data);
        
        // Initialize visible layers
        const layers = [...new Set(data.entities?.map(e => e.layer) || [])];
        const layerVisibility = {};
        layers.forEach(layer => {
          layerVisibility[layer] = true;
        });
        setLayersVisible(layerVisibility);
        setVisibleLayers(layers);
        
        // Initialize entity type visibility
        const entityTypes = [...new Set(data.entities?.map(e => e.type) || [])];
        const typeVisibility = {};
        entityTypes.forEach(type => {
          typeVisibility[type] = true;
        });
        setEntityTypeVisibility(typeVisibility);
        
        // Set metrics if available
        if (data.metrics) {
          setMetrics(data.metrics);
        }
        
        setLoading(false);
      } catch (err) {
        setError('Failed to load drawing data. Please try again.');
        setLoading(false);
        console.error('Error loading drawing:', err);
      }
    };

    if (fileId) {
      loadDrawing();
    }
  }, [fileId]);

  // Adjust viewport when container size changes
  useEffect(() => {
    const updateViewportSize = () => {
      if (containerRef.current) {
        const { clientWidth, clientHeight } = containerRef.current;
        setViewportSize({ 
          width: clientWidth, 
          height: clientHeight - 50 // Adjust for toolbar height
        });
      }
    };

    updateViewportSize();
    
    const resizeObserver = new ResizeObserver(updateViewportSize);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }
    
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  // Auto-fit drawing to viewport when first loaded
  useEffect(() => {
    if (drawing && drawing.entities?.length > 0 && viewportSize.width > 0) {
      fitDrawingToViewport();
    }
  }, [drawing, viewportSize]);

  // Calculate drawing bounds
  const calculateBounds = () => {
    if (!drawing || !drawing.entities?.length) return null;

    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    drawing.entities.forEach(entity => {
      // Handle different entity types
      if (entity.type === 'LINE' || entity.type === 'POLYLINE' || entity.type === 'SPLINE') {
        (entity.points || []).forEach(point => {
          minX = Math.min(minX, point.x);
          minY = Math.min(minY, point.y);
          maxX = Math.max(maxX, point.x);
          maxY = Math.max(maxY, point.y);
        });
      } else if (entity.type === 'CIRCLE') {
        const { center, radius } = entity;
        if (center && radius) {
          minX = Math.min(minX, center.x - radius);
          minY = Math.min(minY, center.y - radius);
          maxX = Math.max(maxX, center.x + radius);
          maxY = Math.max(maxY, center.y + radius);
        }
      } else if (entity.type === 'ARC') {
        const { center, radius } = entity;
        if (center && radius) {
          minX = Math.min(minX, center.x - radius);
          minY = Math.min(minY, center.y - radius);
          maxX = Math.max(maxX, center.x + radius);
          maxY = Math.max(maxY, center.y + radius);
        }
      } else if (entity.type === 'ELLIPSE') {
        if (entity.center && entity.major_radius && entity.minor_radius) {
          const majorRadius = entity.major_radius;
          const minorRadius = entity.minor_radius;
          const rotation = entity.rotation || 0;
          const radRotation = rotation * Math.PI / 180;
          
          // Account for rotation in calculating bounding box
          const rotatedWidth = Math.abs(majorRadius * Math.cos(radRotation)) + 
                             Math.abs(minorRadius * Math.sin(radRotation));
          const rotatedHeight = Math.abs(majorRadius * Math.sin(radRotation)) + 
                              Math.abs(minorRadius * Math.cos(radRotation));
          
          minX = Math.min(minX, entity.center.x - rotatedWidth);
          minY = Math.min(minY, entity.center.y - rotatedHeight);
          maxX = Math.max(maxX, entity.center.x + rotatedWidth);
          maxY = Math.max(maxY, entity.center.y + rotatedHeight);
        } else if (entity.center && entity.major_axis) {
          // Alternative calculation using major axis and ratio
          const majorRadius = Math.sqrt(
            entity.major_axis.x * entity.major_axis.x + 
            entity.major_axis.y * entity.major_axis.y +
            (entity.major_axis.z || 0) * (entity.major_axis.z || 0)
          );
          const minorRadius = majorRadius * (entity.ratio || 0.5);
          
          minX = Math.min(minX, entity.center.x - majorRadius);
          minY = Math.min(minY, entity.center.y - minorRadius);
          maxX = Math.max(maxX, entity.center.x + majorRadius);
          maxY = Math.max(maxY, entity.center.y + minorRadius);
        }
      } else if (entity.type === 'TEXT') {
        const { position } = entity;
        if (position) {
          minX = Math.min(minX, position.x);
          minY = Math.min(minY, position.y);
          maxX = Math.max(maxX, position.x + (entity.text?.length * (entity.height || 10) / 2 || 10));
          maxY = Math.max(maxY, position.y + (entity.height || 10));
        }
      } else if (entity.type === 'BLOCK') {
        const { insert_point } = entity;
        if (insert_point) {
          minX = Math.min(minX, insert_point.x);
          minY = Math.min(minY, insert_point.y);
          maxX = Math.max(maxX, insert_point.x);
          maxY = Math.max(maxY, insert_point.y);
        }
        
        // Process nested entities in the block
        if (entity.entities) {
          entity.entities.forEach(childEntity => {
            // Recursive bounds calculation for child entities
            if (childEntity.points) {
              childEntity.points.forEach(point => {
                minX = Math.min(minX, point.x);
                minY = Math.min(minY, point.y);
                maxX = Math.max(maxX, point.x);
                maxY = Math.max(maxY, point.y);
              });
            } else if (childEntity.center) {
              minX = Math.min(minX, childEntity.center.x - (childEntity.radius || 0));
              minY = Math.min(minY, childEntity.center.y - (childEntity.radius || 0));
              maxX = Math.max(maxX, childEntity.center.x + (childEntity.radius || 0));
              maxY = Math.max(maxY, childEntity.center.y + (childEntity.radius || 0));
            } else if (childEntity.position) {
              minX = Math.min(minX, childEntity.position.x);
              minY = Math.min(minY, childEntity.position.y);
              maxX = Math.max(maxX, childEntity.position.x);
              maxY = Math.max(maxY, childEntity.position.y);
            }
          });
        }
      } else if (entity.type === 'HATCH' || entity.type === 'FACE' || entity.type === 'SOLID') {
        // For hatch and solid, use points array if available
        if (entity.points) {
          entity.points.forEach(point => {
            minX = Math.min(minX, point.x);
            minY = Math.min(minY, point.y);
            maxX = Math.max(maxX, point.x);
            maxY = Math.max(maxY, point.y);
          });
        }
      } else if (entity.type === 'DIMENSION') {
        // For dimensions, use key_points or points array
        if (entity.key_points) {
          Object.values(entity.key_points).forEach(point => {
            minX = Math.min(minX, point.x);
            minY = Math.min(minY, point.y);
            maxX = Math.max(maxX, point.x);
            maxY = Math.max(maxY, point.y);
          });
        } else if (entity.points) {
          entity.points.forEach(point => {
            minX = Math.min(minX, point.x);
            minY = Math.min(minY, point.y);
            maxX = Math.max(maxX, point.x);
            maxY = Math.max(maxY, point.y);
          });
        }
      } else if (entity.position) {
        // Generic fallback for any entity with a position
        minX = Math.min(minX, entity.position.x);
        minY = Math.min(minY, entity.position.y);
        maxX = Math.max(maxX, entity.position.x);
        maxY = Math.max(maxY, entity.position.y);
      }
    });

    // If we couldn't calculate bounds from entities, use metadata
    if (minX === Infinity || maxX === -Infinity) {
      if (drawing.metadata && drawing.metadata.extents) {
        const { min, max } = drawing.metadata.extents;
        return { minX: min.x, minY: min.y, maxX: max.x, maxY: max.y };
      }
      return { minX: -100, minY: -100, maxX: 100, maxY: 100 }; // Default fallback
    }

    return { minX, minY, maxX, maxY };
  };

  // Fit drawing to viewport
  const fitDrawingToViewport = () => {
    const bounds = calculateBounds();
    if (!bounds) return;

    const { minX, minY, maxX, maxY } = bounds;
    const drawingWidth = maxX - minX;
    const drawingHeight = maxY - minY;
    
    if (drawingWidth === 0 || drawingHeight === 0) return;

    const scaleX = viewportSize.width / (drawingWidth * 1.1);
    const scaleY = viewportSize.height / (drawingHeight * 1.1);
    const newScale = Math.min(scaleX, scaleY);

    const drawingCenterX = (minX + maxX) / 2;
    const drawingCenterY = (minY + maxY) / 2;
    
    const newPosition = {
      x: viewportSize.width / 2 - drawingCenterX * newScale,
      y: viewportSize.height / 2 - drawingCenterY * newScale
    };

    setScale(newScale);
    setPosition(newPosition);
  };

  // Determine if an entity is in viewport
  const isEntityVisible = (entity) => {
    if (!entity) return false;
    
    // Skip hidden layers
    if (!layersVisible[entity.layer]) return false;
    
    // Skip hidden entity types
    if (!entityTypeVisibility[entity.type]) return false;
    
    // Filter by status if needed
    if (filtering && filterByStatus && entity.status !== filterByStatus) return false;
    
    // Define viewport boundaries in model coordinates
    const vpMinX = -position.x / scale;
    const vpMinY = -position.y / scale;
    const vpMaxX = (viewportSize.width - position.x) / scale;
    const vpMaxY = (viewportSize.height - position.y) / scale;
    
    // Add padding to viewport
    const padding = 200; // Units in model space
    
    // Check by entity type
    if (entity.type === 'LINE' || entity.type === 'POLYLINE' || entity.type === 'SPLINE') {
      // Check if any point is in viewport
      return entity.points?.some(p => 
        p.x >= vpMinX - padding && p.x <= vpMaxX + padding && 
        p.y >= vpMinY - padding && p.y <= vpMaxY + padding
      );
    }
    
    if (entity.type === 'CIRCLE' || entity.type === 'ARC') {
      const center = entity.center;
      const radius = entity.radius || 0;
      if (!center) return false;
      
      return center.x + radius >= vpMinX - padding && 
             center.x - radius <= vpMaxX + padding &&
             center.y + radius >= vpMinY - padding && 
             center.y - radius <= vpMaxY + padding;
    }
    
    if (entity.type === 'ELLIPSE') {
      const center = entity.center;
      if (!center) return false;
      
      // Use the major radius as approximation
      const majorRadius = entity.major_radius || 
                          (entity.major_axis ? Math.sqrt(
                             entity.major_axis.x * entity.major_axis.x + 
                             entity.major_axis.y * entity.major_axis.y) : 0);
      
      return center.x + majorRadius >= vpMinX - padding && 
             center.x - majorRadius <= vpMaxX + padding &&
             center.y + majorRadius >= vpMinY - padding && 
             center.y - majorRadius <= vpMaxY + padding;
    }
    
    if (entity.type === 'TEXT') {
      const position = entity.position;
      if (!position) return false;
      
      return position.x >= vpMinX - padding && position.x <= vpMaxX + padding &&
             position.y >= vpMinY - padding && position.y <= vpMaxY + padding;
    }
    
    if (entity.type === 'BLOCK') {
      const insert = entity.insert_point;
      if (!insert) return false;
      
      let isVisible = insert.x >= vpMinX - padding && insert.x <= vpMaxX + padding &&
                      insert.y >= vpMinY - padding && insert.y <= vpMaxY + padding;
                      
      // If block has nested entities, check them too
      if (!isVisible && entity.entities) {
        isVisible = entity.entities.some(childEntity => isEntityVisible(childEntity));
      }
      
      return isVisible;
    }
    
    if (entity.type === 'HATCH' || entity.type === 'FACE' || entity.type === 'SOLID') {
      // Check if any point is in viewport
      return entity.points?.some(p => 
        p.x >= vpMinX - padding && p.x <= vpMaxX + padding && 
        p.y >= vpMinY - padding && p.y <= vpMaxY + padding
      );
    }
    
    // For other types, check the position if available
    if (entity.position) {
      return entity.position.x >= vpMinX - padding && entity.position.x <= vpMaxX + padding &&
             entity.position.y >= vpMinY - padding && entity.position.y <= vpMaxY + padding;
    }
    
    // For other types, default to visible
    return true;
  };

  // Handle mouse wheel for zooming - optimized
  const handleWheel = (e) => {
    e.evt.preventDefault();
    
    const stage = stageRef.current;
    if (!stage) return;
    
    const oldScale = scale;
    const pointerPos = stage.getPointerPosition();
    if (!pointerPos) return;
    
    const mousePointTo = {
      x: pointerPos.x / oldScale - position.x / oldScale,
      y: pointerPos.y / oldScale - position.y / oldScale
    };
    
    // Scale factor: zoom in or out
    const scaleBy = 1.1;
    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
    
    // Limit scale range
    const limitedScale = Math.max(0.01, Math.min(100, newScale));
    
    const newPos = {
      x: -(mousePointTo.x - pointerPos.x / limitedScale) * limitedScale,
      y: -(mousePointTo.y - pointerPos.y / limitedScale) * limitedScale
    };
    
    debouncedSetScale(limitedScale);
    debouncedSetPosition(newPos);
  };

  // Handle mouse down for panning - optimized
  const handleMouseDown = (e) => {
    if (e.evt.button === 0) { // Left mouse button
      if (!selectionMode) {
        setIsPanning(true);
      }
    }
  };

  // Handle mouse move for panning - optimized
  const handleMouseMove = (e) => {
    if (isPanning) {
      const dx = e.evt.movementX;
      const dy = e.evt.movementY;
      
      debouncedSetPosition({
        x: position.x + dx,
        y: position.y + dy
      });
    }
  };

  // Handle mouse up to stop panning
  const handleMouseUp = () => {
    setIsPanning(false);
  };

  // Simplify polyline for better performance
  const simplifyPolyline = (points, tolerance = 5) => {
    if (!points || points.length <= 2) return points;
    
    // Simple implementation of Ramer-Douglas-Peucker algorithm
    const findPerpendicularDistance = (p, p1, p2) => {
      const dx = p2.x - p1.x;
      const dy = p2.y - p1.y;
      
      // If points are the same, return distance to one of them
      if (dx === 0 && dy === 0) {
        const dX = p.x - p1.x;
        const dY = p.y - p1.y;
        return Math.sqrt(dX * dX + dY * dY);
      }
      
      // Calculate perpendicular distance
      const norm = Math.sqrt(dx * dx + dy * dy);
      return Math.abs((p.y - p1.y) * dx - (p.x - p1.x) * dy) / norm;
    };
    
    // Find point with max distance
    let maxDistance = 0;
    let index = 0;
    
    for (let i = 1; i < points.length - 1; i++) {
      const distance = findPerpendicularDistance(points[i], points[0], points[points.length - 1]);
      if (distance > maxDistance) {
        maxDistance = distance;
        index = i;
      }
    }
    
    // If max distance > tolerance, recursively simplify
    if (maxDistance > tolerance) {
      const firstPart = simplifyPolyline(points.slice(0, index + 1), tolerance);
      const secondPart = simplifyPolyline(points.slice(index), tolerance);
      
      // Concat but avoid duplicating the point at index
      return firstPart.slice(0, firstPart.length - 1).concat(secondPart);
    } else {
      // Return just the endpoints
      return [points[0], points[points.length - 1]];
    }
  };

  // Handle entity click - now with debugging
  const handleEntityClick = (entity) => {
    if (debug) {
      console.log('Entity clicked:', entity.id, entity.type);
    }
    
    if (selectionMode) {
      handleElementSelect(entity);
    } else {
      setSelectedEntity(entity);
      setInfoDrawerOpen(true);
      setInfoTabValue(0); // Reset to info tab
    }
  };

  // Handle element selection in selection mode
  const handleElementSelect = (entity) => {
    if (!selectionMode || !allowedElementTypes.includes(entity.type)) return;
    
    if (debug) {
      console.log('Selecting entity:', entity.id, entity.type);
    }
    
    setSelectedElements(prev => {
      if (prev.includes(entity.id)) {
        return prev.filter(id => id !== entity.id);
      }
      return [...prev, entity.id];
    });
  };

  // Handle status change
  const handleStatusChange = async (entityId, newStatus) => {
    try {
      // Update color for the selected entity
      const color = STATUS_COLORS[newStatus];
      
      // Call API to update status
      await updateEntityStatus(fileId, entityId, newStatus, color);
      
      // Update local state
      setDrawing(prevDrawing => {
        const updatedEntities = prevDrawing.entities.map(entity => {
          if (entity.id === entityId) {
            return { ...entity, status: newStatus, color };
          }
          return entity;
        });
        
        return { ...prevDrawing, entities: updatedEntities };
      });
      
      // Also update the selected entity if it's the one we changed
      if (selectedEntity && selectedEntity.id === entityId) {
        setSelectedEntity(prev => ({ ...prev, status: newStatus, color }));
      }
      
      // Refresh metrics
      try {
        const updatedMetrics = await fetchMetrics(fileId);
        setMetrics(updatedMetrics);
      } catch (err) {
        console.error('Error refreshing metrics:', err);
      }
      
      showNotification('Status updated successfully', 'success');
    } catch (err) {
      console.error('Error updating entity status:', err);
      setError('Failed to update status. Please try again.');
      showNotification('Failed to update status', 'error');
    }
  };

  // Apply status to selected elements
  const applyStatusToSelected = async () => {
    if (selectedElements.length === 0) return;
    
    try {
      if (debug) {
        console.log('Applying status to elements:', selectedElements.length);
      }
      
      // Call API to update status for multiple elements
      const response = await updateElementsStatus(fileId, {
        element_ids: selectedElements,
        status: workStatus,
        color: STATUS_COLORS[workStatus]
      });
      
      // Update local state
      setDrawing(prev => {
        const updatedEntities = prev.entities.map(entity => {
          if (selectedElements.includes(entity.id)) {
            return { ...entity, status: workStatus, color: STATUS_COLORS[workStatus] };
          }
          return entity;
        });
        
        return { ...prev, entities: updatedEntities };
      });
      
      // Update metrics
      if (response.metrics) {
        setMetrics(response.metrics);
      }
      
      showNotification(`Status updated for ${selectedElements.length} elements`, 'success');
      
      // Clear selection
      setSelectedElements([]);
      
    } catch (err) {
      console.error('Error updating multiple elements:', err);
      showNotification('Failed to update elements', 'error');
    }
  };

  // Handle element type selection change
  const handleElementTypeChange = (e) => {
    const { value, checked } = e.target;
    
    setAllowedElementTypes(prev => {
      if (checked) {
        return [...prev, value];
      } else {
        return prev.filter(type => type !== value);
      }
    });
  };

  // Toggle layer visibility
  const toggleLayer = (layer) => {
    setLayersVisible(prev => {
      const updated = { ...prev, [layer]: !prev[layer] };
      
      // Update visible layers array
      const visible = Object.keys(updated).filter(key => updated[key]);
      setVisibleLayers(visible);
      
      return updated;
    });
  };
  
  // Toggle entity type visibility
  const toggleEntityType = (type) => {
    setEntityTypeVisibility(prev => {
      const updated = { ...prev, [type]: !prev[type] };
      return updated;
    });
  };

  // Zoom controls - optimized
  const zoomIn = () => {
    debouncedSetScale(prev => Math.min(100, prev * 1.2));
  };

  const zoomOut = () => {
    debouncedSetScale(prev => Math.max(0.01, prev / 1.2));
  };

  // Reset view
  const resetView = () => {
    fitDrawingToViewport();
  };

  // Toggle selection mode - improved
  const toggleSelectionMode = () => {
    const newMode = !selectionMode;
    setSelectionMode(newMode);
    if (!newMode) {
      // If turning off selection mode, clear selected elements
      setSelectedElements([]);
    } else {
      // If turning on selection mode, show the tool drawer
      setSelectionToolDrawerOpen(true);
    }
  };
  
  // Toggle 3D mode
  const toggle3DMode = () => {
    setIs3DMode(!is3DMode);
  };
  
  // Toggle status filter
  const toggleStatusFilter = (status) => {
    if (filterByStatus === status) {
      // Turning off filter
      setFilterByStatus(null);
      setFiltering(false);
    } else {
      // Setting new filter
      setFilterByStatus(status);
      setFiltering(true);
    }
  };
  
  // Handle info tab change
  const handleInfoTabChange = (event, newValue) => {
    setInfoTabValue(newValue);
  };
  
  // Export drawing as report
  const exportDrawing = async (format = 'json') => {
    try {
      const response = await generateReport(fileId, format);
      
      // Download the report
      const link = document.createElement('a');
      const blob = new Blob([JSON.stringify(response, null, 2)], { type: 'application/json' });
      link.href = URL.createObjectURL(blob);
      link.download = `drawing_${fileId}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      showNotification('Report exported successfully', 'success');
    } catch (err) {
      console.error('Error exporting drawing:', err);
      showNotification('Failed to export drawing', 'error');
    }
  };

  // Calculate entity colors based on status and selection
  const getEntityColor = (entity) => {
    // Default to entity's own color
    let color = entity.color || '#000000';
    
    // If entity has status, use status color unless overridden
    if (entity.status && STATUS_COLORS[entity.status]) {
      color = STATUS_COLORS[entity.status];
    }
    
    // For selected elements in selection mode, override with selection color
    if (selectionMode && selectedElements.includes(entity.id)) {
      return '#3498db'; // Selection blue
    }
    
    return color;
  };

  // Filter visible entities for better performance
  const visibleEntities = useMemo(() => {
    if (!drawing || !drawing.entities) return [];
    
    // Filter by layer visibility, entity type visibility, and viewport
    return drawing.entities.filter(entity => 
      layersVisible[entity.layer] && 
      entityTypeVisibility[entity.type] && 
      (!filtering || filterByStatus === null || entity.status === filterByStatus) &&
      isEntityVisible(entity)
    );
  }, [
    drawing, 
    layersVisible, 
    entityTypeVisibility,
    filtering,
    filterByStatus,
    position.x, 
    position.y, 
    scale, 
    viewportSize.width, 
    viewportSize.height
  ]);

  // Render an entity with improved selection visuals
  const renderEntity = (entity, index) => {
    // Skip if entity's layer is not visible
    if (!layersVisible[entity.layer]) return null;
    
    // Skip if entity's type is not visible
    if (!entityTypeVisibility[entity.type]) return null;
    
    // Skip if filtering by status
    if (filtering && filterByStatus && entity.status !== filterByStatus) return null;

    // Determine appropriate level of detail based on scale
    let simplifiedEntity = { ...entity };
    if (scale < 0.05) {
      // For very small scales, simplify geometry
      if (entity.type === 'POLYLINE' && entity.points && entity.points.length > 10) {
        simplifiedEntity = { 
          ...entity, 
          points: simplifyPolyline(entity.points, 10 / scale)
        };
      } else if (entity.type === 'SPLINE' && entity.points && entity.points.length > 10) {
        simplifiedEntity = {
          ...entity,
          points: simplifyPolyline(entity.points, 15 / scale)
        };
      }
    }

    // Determine color based on status or selection
    const entityColor = getEntityColor(entity);
    const isSelected = selectedElements.includes(entity.id);
    
    // Base stroke width - at least 1 pixel regardless of zoom
    const baseStrokeWidth = Math.max(1 / scale, 1);
    const strokeWidth = isSelected ? 3 / scale : baseStrokeWidth;
    
    // Larger hit area for easier selection
    const hitStrokeWidth = 10 / scale;
    
    // Add shadow for selected elements - more noticeable
    const shadowProps = isSelected ? {
      shadowColor: '#3498db',
      shadowBlur: 15,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      stroke: '#3498db', // Override color for selected elements
    } : {};

    switch (entity.type) {
      case 'LINE':
        if (!entity.points || entity.points.length < 2) return null;
        
        return (
          <Line
            key={`${entity.id}-${index}`}
            points={entity.points.flatMap(point => [point.x, point.y])}
            stroke={isSelected ? '#3498db' : entityColor}
            strokeWidth={strokeWidth}
            hitStrokeWidth={hitStrokeWidth}
            onClick={() => handleEntityClick(entity)}
            onTap={() => handleEntityClick(entity)}
            {...shadowProps}
            entityId={entity.id}
          />
        );
        
      case 'POLYLINE':
        if (!entity.points || entity.points.length < 2) return null;
        
        return (
          <Line
            key={`${entity.id}-${index}`}
            points={simplifiedEntity.points.flatMap(point => [point.x, point.y])}
            stroke={isSelected ? '#3498db' : entityColor}
            strokeWidth={strokeWidth}
            hitStrokeWidth={hitStrokeWidth}
            closed={entity.closed}
            fill={entity.closed ? `${entityColor}33` : undefined} // Add light fill for closed polylines
            onClick={() => handleEntityClick(entity)}
            onTap={() => handleEntityClick(entity)}
            {...shadowProps}
            entityId={entity.id}
          />
        );
        
      case 'CIRCLE':
        if (!entity.center || !entity.radius) return null;
        
        return (
          <Circle
            key={`${entity.id}-${index}`}
            x={entity.center.x}
            y={entity.center.y}
            radius={entity.radius}
            stroke={isSelected ? '#3498db' : entityColor}
            strokeWidth={strokeWidth}
            hitStrokeWidth={hitStrokeWidth}
            onClick={() => handleEntityClick(entity)}
            onTap={() => handleEntityClick(entity)}
            {...shadowProps}
            entityId={entity.id}
          />
        );
        
      case 'ARC':
        if (!entity.center || !entity.radius) return null;
        
        // For arcs, we need to draw a custom path
        return (
          <Shape
            key={`${entity.id}-${index}`}
            sceneFunc={(context, shape) => {
              context.beginPath();
              const startRad = (entity.start_angle * Math.PI) / 180;
              const endRad = (entity.end_angle * Math.PI) / 180;
              
              // Adjust end angle for proper arc drawing
              let adjustedEndRad = endRad;
              if (endRad < startRad) {
                adjustedEndRad += 2 * Math.PI;
              }
              
              context.arc(
                entity.center.x,
                entity.center.y,
                entity.radius,
                startRad,
                adjustedEndRad,
                false
              );
              context.stroke();
            }}
            stroke={isSelected ? '#3498db' : entityColor}
            strokeWidth={strokeWidth}
            hitStrokeWidth={hitStrokeWidth}
            onClick={() => handleEntityClick(entity)}
            onTap={() => handleEntityClick(entity)}
            {...shadowProps}
            entityId={entity.id}
          />
        );
        
      case 'ELLIPSE':
        if (!entity.center) return null;
        
        let majorRadius, minorRadius, rotation;
        
        // Get parameters from either format
        if (entity.major_radius && entity.minor_radius) {
          majorRadius = entity.major_radius;
          minorRadius = entity.minor_radius;
          rotation = entity.rotation || 0;
        } else if (entity.major_axis && entity.ratio) {
          // Calculate from major axis vector and ratio
          majorRadius = Math.sqrt(
            entity.major_axis.x * entity.major_axis.x + 
            entity.major_axis.y * entity.major_axis.y
          );
          minorRadius = majorRadius * entity.ratio;
          
          // Calculate rotation based on major axis
          rotation = Math.atan2(entity.major_axis.y, entity.major_axis.x) * 180 / Math.PI;
        } else {
          return null; // Not enough data to render
        }
        
        return (
          <Ellipse
            key={`${entity.id}-${index}`}
            x={entity.center.x}
            y={entity.center.y}
            radiusX={majorRadius}
            radiusY={minorRadius}
            rotation={rotation}
            stroke={isSelected ? '#3498db' : entityColor}
            strokeWidth={strokeWidth}
            hitStrokeWidth={hitStrokeWidth}
            onClick={() => handleEntityClick(entity)}
            onTap={() => handleEntityClick(entity)}
            {...shadowProps}
            entityId={entity.id}
          />
        );
        
      case 'TEXT':
        if (!entity.position || !entity.text) return null;
        
        // Determine alignment
        let textProps = {
          align: 'left',
          verticalAlign: 'middle'
        };
        
        if (entity.halign) {
          if (entity.halign === 'CENTER') textProps.align = 'center';
          else if (entity.halign === 'RIGHT') textProps.align = 'right';
        }
        
        if (entity.valign) {
          if (entity.valign === 'TOP') textProps.verticalAlign = 'top';
          else if (entity.valign === 'BOTTOM') textProps.verticalAlign = 'bottom';
        }
        
        return (
          <Group 
            key={`${entity.id}-${index}`}
            x={entity.position.x}
            y={entity.position.y}
            rotation={entity.rotation || 0}
            onClick={() => handleEntityClick(entity)}
            onTap={() => handleEntityClick(entity)}
            {...shadowProps}
            entityId={entity.id}
          >
            <Text
              text={entity.text}
              fill={isSelected ? '#3498db' : entityColor}
              fontSize={(entity.height || 10) / scale}
              fontStyle={entity.bold ? 'bold' : 'normal'}
              fontFamily={entity.style || 'Arial'}
              {...textProps}
            />
          </Group>
        );
        
      case 'SPLINE':
        if (!entity.points || entity.points.length < 2) return null;
        
        return (
          <Line
            key={`${entity.id}-${index}`}
            points={simplifiedEntity.points.flatMap(point => [point.x, point.y])}
            stroke={isSelected ? '#3498db' : entityColor}
            strokeWidth={strokeWidth}
            hitStrokeWidth={hitStrokeWidth}
            tension={0.5} // Add tension for smooth curves
            onClick={() => handleEntityClick(entity)}
            onTap={() => handleEntityClick(entity)}
            {...shadowProps}
            entityId={entity.id}
          />
        );
        
      case 'HATCH':
        if (!entity.points || entity.points.length < 3) return null;
        
        return (
          <Line
            key={`${entity.id}-${index}`}
            points={entity.points.flatMap(point => [point.x, point.y])}
            stroke={isSelected ? '#3498db' : entityColor}
            strokeWidth={strokeWidth}
            hitStrokeWidth={hitStrokeWidth}
            closed={true}
            fill={entity.solid_fill ? entityColor : `${entityColor}22`}
            onClick={() => handleEntityClick(entity)}
            onTap={() => handleEntityClick(entity)}
            {...shadowProps}
            entityId={entity.id}
          />
        );
        
      case 'FACE':
      case 'SOLID':
        if (!entity.points || entity.points.length < 3) return null;
        
        return (
          <Line
            key={`${entity.id}-${index}`}
            points={entity.points.flatMap(point => [point.x, point.y])}
            stroke={isSelected ? '#3498db' : entityColor}
            strokeWidth={strokeWidth}
            hitStrokeWidth={hitStrokeWidth}
            closed={true}
            fill={isSelected ? 'rgba(52, 152, 219, 0.3)' : `${entityColor}33`}
            onClick={() => handleEntityClick(entity)}
            onTap={() => handleEntityClick(entity)}
            {...shadowProps}
            entityId={entity.id}
          />
        );
        
      case 'DIMENSION':
        if (!entity.points || entity.points.length < 2) return null;
        
        // For dimensions, render lines and text
        const DimensionGroup = () => {
          // Define points for drawing the dimension
          const linePoints = entity.points.flatMap(point => [point.x, point.y]);
          
          return (
            <Group
              onClick={() => handleEntityClick(entity)}
              onTap={() => handleEntityClick(entity)}
              {...shadowProps}
              entityId={entity.id}
            >
              {/* Dimension lines */}
              <Line
                points={linePoints}
                stroke={isSelected ? '#3498db' : entityColor}
                strokeWidth={strokeWidth}
                hitStrokeWidth={hitStrokeWidth}
              />
              
              {/* Dimension text, if available */}
              {entity.text && entity.text_position && (
                <Text
                  x={entity.text_position.x}
                  y={entity.text_position.y}
                  text={entity.text || (entity.measurement ? `${entity.measurement.toFixed(2)}` : '')}
                  fill={isSelected ? '#3498db' : entityColor}
                  fontSize={12 / scale}
                  align="center"
                  verticalAlign="middle"
                />
              )}
            </Group>
          );
        };
        
        return <DimensionGroup key={`${entity.id}-${index}`} />;
        
      case 'BLOCK':
        if (!entity.insert_point) return null;
        
        // For blocks, render all nested entities plus insertion point marker
        const BlockGroup = () => {
          // Base marker for insertion point
          const insertPoint = entity.insert_point;
          const size = 5 / scale;
          
          return (
            <Group
              onClick={() => handleEntityClick(entity)}
              onTap={() => handleEntityClick(entity)}
              {...shadowProps}
              entityId={entity.id}
            >
              {/* Marker for insertion point */}
              <Line
                points={[
                  insertPoint.x - size, insertPoint.y,
                  insertPoint.x + size, insertPoint.y
                ]}
                stroke={isSelected ? '#3498db' : entityColor}
                strokeWidth={strokeWidth}
              />
              <Line
                points={[
                  insertPoint.x, insertPoint.y - size,
                  insertPoint.x, insertPoint.y + size
                ]}
                stroke={isSelected ? '#3498db' : entityColor}
                strokeWidth={strokeWidth}
              />
              
              {/* Block name, if scale is large enough */}
              {scale > 0.05 && (
                <Text
                  x={insertPoint.x + size}
                  y={insertPoint.y + size}
                  text={entity.name || "BLOCK"}
                  fill={isSelected ? '#3498db' : entityColor}
                  fontSize={10 / scale}
                />
              )}
              
              {/* Render nested entities if they exist and are visible */}
              {entity.entities && entity.entities.map((nestedEntity, nestedIndex) => {
                // Skip if nested entity's layer is not visible
                if (!layersVisible[nestedEntity.layer]) return null;
                
                // Set parent block info for debugging
                const enhancedEntity = {
                  ...nestedEntity,
                  parent_block_id: entity.id
                };
                
                // Use the same renderer for nested entities
                return renderEntity(enhancedEntity, `${entity.id}-nested-${nestedIndex}`);
              })}
            </Group>
          );
        };
        
        return <BlockGroup key={`${entity.id}-${index}`} />;
        
      case 'POINT':
        if (!entity.position) return null;
        
        return (
          <Circle
            key={`${entity.id}-${index}`}
            x={entity.position.x}
            y={entity.position.y}
            radius={2 / scale}
            fill={isSelected ? '#3498db' : entityColor}
            hitStrokeWidth={hitStrokeWidth}
            onClick={() => handleEntityClick(entity)}
            onTap={() => handleEntityClick(entity)}
            {...shadowProps}
            entityId={entity.id}
          />
        );
        
      default:
        // For unhandled types, show a placeholder
        if (entity.center) {
          return (
            <Circle
              key={`${entity.id}-${index}`}
              x={entity.center.x}
              y={entity.center.y}
              radius={3 / scale}
              fill={isSelected ? '#3498db' : "#FF0000"}
              hitStrokeWidth={hitStrokeWidth}
              onClick={() => handleEntityClick(entity)}
              onTap={() => handleEntityClick(entity)}
              {...shadowProps}
              entityId={entity.id}
            />
          );
        } else if (entity.position) {
          return (
            <Circle
              key={`${entity.id}-${index}`}
              x={entity.position.x}
              y={entity.position.y}
              radius={3 / scale}
              fill={isSelected ? '#3498db' : "#FF0000"}
              hitStrokeWidth={hitStrokeWidth}
              onClick={() => handleEntityClick(entity)}
              onTap={() => handleEntityClick(entity)}
              {...shadowProps}
              entityId={entity.id}
            />
          );
        } else if (entity.points && entity.points.length > 0) {
          // If it has points, use the first point as center
          return (
            <Circle
              key={`${entity.id}-${index}`}
              x={entity.points[0].x}
              y={entity.points[0].y}
              radius={3 / scale}
              fill={isSelected ? '#3498db' : "#FF0000"}
              hitStrokeWidth={hitStrokeWidth}
              onClick={() => handleEntityClick(entity)}
              onTap={() => handleEntityClick(entity)}
              {...shadowProps}
              entityId={entity.id}
            />
          );
        }
        return null;
    }
  };

  // Render entity status statistics
  const renderStatusStats = () => {
    if (!metrics) return null;
    
    const statusCounts = {
      completed: metrics.progress?.completed_count || 0,
      in_progress: metrics.progress?.in_progress_count || 0,
      delayed: metrics.progress?.delayed_count || 0,
      not_started: metrics.progress?.not_started_count || 0
    };
    
    const totalCount = Object.values(statusCounts).reduce((a, b) => a + b, 0);
    
    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          Status Distribution
        </Typography>
        
        <Grid container spacing={1}>
          {Object.entries(statusCounts).map(([status, count]) => (
            <Grid item xs={6} key={status}>
              <Button
                variant={filterByStatus === status ? "contained" : "outlined"}
                size="small"
                onClick={() => toggleStatusFilter(status)}
                startIcon={
                  <Box sx={{ 
                    width: 12, 
                    height: 12, 
                    bgcolor: STATUS_COLORS[status], 
                    borderRadius: '50%' 
                  }}/>
                }
                sx={{ width: '100%', justifyContent: 'flex-start' }}
              >
                {STATUS_LABELS[status]}: {count} ({totalCount ? Math.round(count / totalCount * 100) : 0}%)
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };
  
  // Render entity type statistics
  const renderEntityTypeStats = () => {
    if (!drawing || !drawing.entities) return null;
    
    const typeCountsObj = {};
    drawing.entities.forEach(entity => {
      if (!entity.parent_block_id) { // Skip nested entities
        typeCountsObj[entity.type] = (typeCountsObj[entity.type] || 0) + 1;
      }
    });
    
    // Convert to array for sorting
    const typeCounts = Object.entries(typeCountsObj)
      .map(([type, count]) => ({ type, count }))
      .sort((a, b) => b.count - a.count);
    
    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2" gutterBottom>
          Entity Types
        </Typography>
        
        <TableContainer component={Paper} variant="outlined" sx={{ maxHeight: 200 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell align="right">Count</TableCell>
                <TableCell align="center">Visible</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {typeCounts.map(({ type, count }) => (
                <TableRow key={type}>
                  <TableCell component="th" scope="row">{type}</TableCell>
                  <TableCell align="right">{count}</TableCell>
                  <TableCell align="center">
                    <Switch
                      size="small"
                      checked={entityTypeVisibility[type] !== false}
                      onChange={() => toggleEntityType(type)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  // Loading state
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  // Error state
  if (error) {
    return (
      <Box sx={{ m: 2 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  // No drawing data
  if (!drawing) {
    return (
      <Box sx={{ m: 2 }}>
        <Alert severity="info">No drawing data available. Please select a different file.</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ height: 'calc(100vh - 120px)', display: 'flex', flexDirection: 'column' }}>
      {/* Заменяем старую панель инструментов на новый компонент ViewerToolbar */}
      <ViewerToolbar
        drawingName={drawing.name || drawing.filename || 'Untitled Drawing'}
        onZoomIn={zoomIn}
        onZoomOut={zoomOut}
        onPan={() => setIsPanning(!isPanning)}
        onReset={resetView}
        onToggleLayers={() => {
          setInfoDrawerOpen(true);
          setInfoTabValue(1); // Switch to layers tab
        }}
        onToggleSelection={toggleSelectionMode}
        onToggle3DMode={toggle3DMode}
        onShowInfo={() => {
          setInfoDrawerOpen(true);
          setInfoTabValue(0); // Switch to info tab
        }}
        onExport={() => exportDrawing('json')}
        isPanning={isPanning}
        isSelectionMode={selectionMode}
        is3DMode={is3DMode}
        selectedElements={selectedElements}
        metrics={metrics}
      />
      
      {/* Selection toolbar */}
      {selectionMode && (
        <Paper sx={{ p: 1, mb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2" sx={{ mr: 2 }}>
              Selected: {selectedElements.length} elements
            </Typography>
            
            <FormControl variant="outlined" size="small" sx={{ minWidth: 150, mr: 2 }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={workStatus}
                onChange={(e) => setWorkStatus(e.target.value)}
                label="Status"
              >
                <MenuItem value="completed">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: 16, height: 16, bgcolor: STATUS_COLORS.completed, mr: 1, borderRadius: '50%' }} />
                    {STATUS_LABELS.completed}
                  </Box>
                </MenuItem>
                <MenuItem value="in_progress">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: 16, height: 16, bgcolor: STATUS_COLORS.in_progress, mr: 1, borderRadius: '50%' }} />
                    {STATUS_LABELS.in_progress}
                  </Box>
                </MenuItem>
                <MenuItem value="delayed">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: 16, height: 16, bgcolor: STATUS_COLORS.delayed, mr: 1, borderRadius: '50%' }} />
                    {STATUS_LABELS.delayed}
                  </Box>
                </MenuItem>
                <MenuItem value="not_started">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box sx={{ width: 16, height: 16, bgcolor: STATUS_COLORS.not_started, mr: 1, borderRadius: '50%' }} />
                    {STATUS_LABELS.not_started}
                  </Box>
                </MenuItem>
              </Select>
            </FormControl>
            
            <Button 
              variant="contained" 
              color="primary"
              onClick={applyStatusToSelected}
              disabled={selectedElements.length === 0}
              size="small"
            >
              Apply Status
            </Button>
          </Box>
          
          <Button 
            variant="outlined" 
            size="small"
            onClick={toggleSelectionMode}
          >
            Exit Selection Mode
          </Button>
        </Paper>
      )}
      
      {/* Status filtering indicators */}
      {filtering && (
        <Paper sx={{ p: 1, mb: 1, bgcolor: '#f5f5f5' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="body2">
              Filtering by status: 
              <Chip
                label={STATUS_LABELS[filterByStatus]}
                size="small"
                sx={{ 
                  ml: 1, 
                  bgcolor: STATUS_COLORS[filterByStatus],
                  color: 'white',
                  fontWeight: 'bold'
                }}
                onDelete={() => toggleStatusFilter(filterByStatus)}
              />
            </Typography>
            
            <Button 
              variant="text" 
              size="small"
              onClick={() => {
                setFilterByStatus(null);
                setFiltering(false);
              }}
            >
              Clear Filter
            </Button>
          </Box>
        </Paper>
      )}
      
      {/* Main content */}
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        {/* Drawing canvas */}
        <Grid item xs={12} ref={containerRef} sx={{ height: '100%' }}>
          <Box 
            sx={{ 
              width: '100%', 
              height: '100%', 
              border: '1px solid #ddd', 
              borderRadius: 1,
              overflow: 'hidden',
              bgcolor: '#f5f5f5',
              cursor: selectionMode ? 'pointer' : isPanning ? 'grab' : 'default'
            }}
          >
            {is3DMode ? (
              <Box sx={{ 
                width: '100%', 
                height: '100%', 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                flexDirection: 'column'
              }}>
                <Typography variant="h6" color="text.secondary">
                  3D View Coming Soon
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  This feature is currently under development.
                </Typography>
                <Button 
                  variant="outlined"
                  onClick={toggle3DMode}
                  sx={{ mt: 2 }}
                >
                  Switch Back to 2D View
                </Button>
              </Box>
            ) : (
              <Stage
                ref={stageRef}
                width={viewportSize.width}
                height={viewportSize.height}
                onWheel={handleWheel}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                draggable={false}
              >
                <Layer>
                  <Group x={position.x} y={position.y} scaleX={scale} scaleY={scale}>
                    {visibleEntities.map(renderEntity)}
                  </Group>
                </Layer>
              </Stage>
            )}
          </Box>
        </Grid>
      </Grid>
      
      {/* Info drawer with tabs */}
      <Drawer
        anchor="right"
        open={infoDrawerOpen}
        onClose={() => setInfoDrawerOpen(false)}
        sx={{ '& .MuiDrawer-paper': { width: 340 } }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
          <Typography variant="h6">Drawing Information</Typography>
          <IconButton onClick={() => setInfoDrawerOpen(false)}>
            <Close />
          </IconButton>
        </Box>
        
        <Divider />
        
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs 
            value={infoTabValue} 
            onChange={handleInfoTabChange} 
            variant="fullWidth"
          >
            <Tab icon={<Info />} label="Info" id="info-tab-0" />
            <Tab icon={<Layers />} label="Layers" id="info-tab-1" />
            <Tab icon={<FormatListBulleted />} label="Entities" id="info-tab-2" />
            <Tab icon={<BarChart />} label="Stats" id="info-tab-3" />
          </Tabs>
        </Box>
        
        {/* Info Tab - Заменяем старую панель информации на новый EntityInfoPanel */}
        <Box hidden={infoTabValue !== 0} sx={{ p: 2, overflowY: 'auto', height: 'calc(100vh - 180px)' }}>
          <EntityInfoPanel
            entity={selectedEntity}
            onStatusChange={handleStatusChange}
            statusOptions={{
              not_started: { label: 'Not Started', color: STATUS_COLORS.not_started },
              in_progress: { label: 'In Progress', color: STATUS_COLORS.in_progress },
              delayed: { label: 'Delayed', color: STATUS_COLORS.delayed },
              completed: { label: 'Completed', color: STATUS_COLORS.completed }
            }}
          />
          
          {/* Если нет выбранной сущности, показываем информацию о чертеже */}
          {!selectedEntity && (
            <>
              <Typography variant="subtitle1" gutterBottom>
                Drawing Information
              </Typography>
              
              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    Filename: {drawing.filename}
                  </Typography>
                  
                  {drawing.name && (
                    <Typography variant="body2" color="text.secondary">
                      Name: {drawing.name}
                    </Typography>
                  )}
                  
                  {drawing.project && (
                    <Typography variant="body2" color="text.secondary">
                      Project: {drawing.project}
                    </Typography>
                  )}
                  
                  <Typography variant="body2" color="text.secondary">
                    Uploaded: {new Date(drawing.upload_date).toLocaleString()}
                  </Typography>
                  
                  <Typography variant="body2" color="text.secondary">
                    Entities: {drawing.entities?.length || 0}
                  </Typography>
                  
                  {drawing.metadata?.dxf_version && (
                    <Typography variant="body2" color="text.secondary">
                      DXF Version: {drawing.metadata.dxf_version}
                    </Typography>
                  )}
                  
                  {drawing.metadata?.units && (
                    <Typography variant="body2" color="text.secondary">
                      Units: {drawing.metadata.units}
                    </Typography>
                  )}
                </CardContent>
              </Card>
              
              {/* Progress Metrics */}
              {metrics && (
                <Box sx={{ mb: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Progress Metrics
                  </Typography>
                  
                  <Card>
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        Total Length: {metrics.totalLength?.toFixed(2) || '0.00'} units
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Completed: {metrics.completedLength?.toFixed(2) || '0.00'} units
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        In Progress: {metrics.inProgressLength?.toFixed(2) || '0.00'} units
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                        Completion: {metrics.completedPercentage?.toFixed(2) || '0.00'}%
                      </Typography>
                      
                      <Box sx={{ mt: 2 }}>
                        <LinearProgress 
                          variant="determinate" 
                          value={metrics.completedPercentage || 0}
                          sx={{ height: 10, borderRadius: 1 }}
                        />
                      </Box>
                      
                      {renderStatusStats()}
                    </CardContent>
                  </Card>
                </Box>
              )}
            </>
          )}
        </Box>
        
        {/* Layers Tab */}
        <Box hidden={infoTabValue !== 1} sx={{ p: 2, overflowY: 'auto', height: 'calc(100vh - 180px)' }}>
          <Typography variant="subtitle1" gutterBottom>
            Layers
          </Typography>
          
          <List dense>
            {Object.keys(layersVisible).map(layer => (
              <ListItem key={layer} disablePadding>
                <ListItemText 
                  primary={layer} 
                  secondary={`${drawing.entities.filter(e => e.layer === layer).length} entities`} 
                />
                <Chip 
                  label={layersVisible[layer] ? 'Visible' : 'Hidden'} 
                  color={layersVisible[layer] ? 'primary' : 'default'}
                  size="small"
                  onClick={() => toggleLayer(layer)}
                  sx={{ cursor: 'pointer' }}
                />
              </ListItem>
            ))}
          </List>
          
          <Divider sx={{ my: 2 }} />
          
          <Button 
            variant="outlined" 
            fullWidth
            onClick={() => {
              // Toggle all layers
              const allVisible = Object.values(layersVisible).every(v => v);
              const newState = !allVisible;
              
              const updatedLayers = {};
              Object.keys(layersVisible).forEach(layer => {
                updatedLayers[layer] = newState;
              });
              
              setLayersVisible(updatedLayers);
              setVisibleLayers(newState ? Object.keys(updatedLayers) : []);
            }}
          >
            {Object.values(layersVisible).every(v => v) ? 'Hide All Layers' : 'Show All Layers'}
          </Button>
        </Box>
        
        {/* Entities Tab */}
        <Box hidden={infoTabValue !== 2} sx={{ p: 2, overflowY: 'auto', height: 'calc(100vh - 180px)' }}>
          <Typography variant="subtitle1" gutterBottom>
            Entity Types
          </Typography>
          
          {renderEntityTypeStats()}
          
          <Divider sx={{ my: 2 }} />
          
          <Typography variant="subtitle1" gutterBottom>
            Selection Settings
          </Typography>
          
          <FormGroup>
            <Typography variant="body2" color="text.secondary" paragraph>
              Entity types that can be selected:
            </Typography>
            
            <Grid container spacing={1}>
              {['LINE', 'POLYLINE', 'CIRCLE', 'ARC', 'ELLIPSE', 'SPLINE', 'TEXT', 'BLOCK', 'HATCH'].map(type => (
                <Grid item xs={6} key={type}>
                  <FormControlLabel
                    control={
                      <Checkbox 
                        size="small"
                        checked={allowedElementTypes.includes(type)} 
                        onChange={handleElementTypeChange} 
                        value={type} 
                      />
                    }
                    label={type}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </Box>
        
        {/* Stats Tab */}
        <Box hidden={infoTabValue !== 3} sx={{ p: 2, overflowY: 'auto', height: 'calc(100vh - 180px)' }}>
          <Typography variant="subtitle1" gutterBottom>
            Statistics
          </Typography>
          
          {metrics && (
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="subtitle2" gutterBottom>
                  Progress
                </Typography>
                
                <LinearProgress 
                  variant="determinate" 
                  value={metrics.completedPercentage || 0}
                  sx={{ height: 10, borderRadius: 1, mb: 2 }}
                />
                
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">
                      Completed: {metrics.completedPercentage?.toFixed(2) || '0.00'}%
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">
                      Remaining: {(100 - (metrics.completedPercentage || 0)).toFixed(2)}%
                    </Typography>
                  </Grid>
                </Grid>
                
                <Divider sx={{ my: 2 }} />
                
                <Typography variant="subtitle2" gutterBottom>
                  Geometry
                </Typography>
                
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">
                      Total Length: {metrics.totalLength?.toFixed(2) || '0.00'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">
                      Total Area: {metrics.geometry?.total_area?.toFixed(2) || '0.00'}
                    </Typography>
                  </Grid>
                </Grid>
                
                <Divider sx={{ my: 2 }} />
                
                <Typography variant="subtitle2" gutterBottom>
                  Entity Counts
                </Typography>
                
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">
                      Total Entities: {metrics.progress?.total_count || drawing.entities?.length || 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">
                      Completed: {metrics.progress?.completed_count || 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">
                      In Progress: {metrics.progress?.in_progress_count || 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">
                      Delayed: {metrics.progress?.delayed_count || 0}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="text.secondary">
                      Not Started: {metrics.progress?.not_started_count || 0}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )}
          
          <Divider sx={{ my: 2 }} />
          
          <Button
            variant="outlined"
            fullWidth
            onClick={() => exportDrawing('json')}
            startIcon={<Save />}
          >
            Export Report
          </Button>
        </Box>
      </Drawer>
      
      {/* Selection Tool Drawer */}
      <Drawer
        anchor="right"
        open={selectionToolDrawerOpen}
        onClose={() => setSelectionToolDrawerOpen(false)}
        sx={{ '& .MuiDrawer-paper': { width: 340 } }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
          <Typography variant="h6">Selection Tool</Typography>
          <IconButton onClick={() => setSelectionToolDrawerOpen(false)}>
            <Close />
          </IconButton>
        </Box>
        
        <Divider />
        
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Selection Mode
          </Typography>
          
          <FormControlLabel
            control={
              <Switch 
                checked={selectionMode} 
                onChange={toggleSelectionMode}
              />
            }
            label={selectionMode ? "Selection mode active" : "Selection mode inactive"}
          />
          
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            In selection mode, click on elements to select them and update their status.
          </Typography>
        </Box>
        
        <Divider />
        
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Element Types
          </Typography>
          
          <Typography variant="body2" color="text.secondary" paragraph>
            Select which element types can be selected:
          </Typography>
          
          <FormGroup>
            <Grid container spacing={1}>
              {['LINE', 'POLYLINE', 'CIRCLE', 'ARC', 'ELLIPSE', 'SPLINE', 'TEXT', 'BLOCK', 'HATCH'].map(type => (
                <Grid item xs={6} key={type}>
                  <FormControlLabel
                    control={
                      <Checkbox 
                        checked={allowedElementTypes.includes(type)} 
                        onChange={handleElementTypeChange} 
                        value={type} 
                      />
                    }
                    label={type}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </Box>
        
        <Divider />
        
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Status Colors
          </Typography>
          
          <List dense>
            {Object.entries(STATUS_LABELS).map(([key, label]) => (
              <ListItem key={key}>
                <Box 
                  sx={{ 
                    width: 20, 
                    height: 20, 
                    borderRadius: '50%', 
                    bgcolor: STATUS_COLORS[key],
                    mr: 2
                  }} 
                />
                <ListItemText primary={label} />
              </ListItem>
            ))}
          </List>
        </Box>
        
        {selectionMode && (
          <>
            <Divider />
            <Box sx={{ p: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                Apply Status
              </Typography>
              
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Status</InputLabel>
                <Select
                  value={workStatus}
                  onChange={(e) => setWorkStatus(e.target.value)}
                  label="Status"
                >
                  {Object.entries(STATUS_LABELS).map(([key, label]) => (
                    <MenuItem key={key} value={key}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: 16, height: 16, bgcolor: STATUS_COLORS[key], mr: 1, borderRadius: '50%' }} />
                        {label}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              
              <Button 
                variant="contained" 
                color="primary"
                onClick={applyStatusToSelected}
                disabled={selectedElements.length === 0}
                fullWidth
              >
                Apply to {selectedElements.length} Selected Elements
              </Button>
            </Box>
          </>
        )}
      </Drawer>
    </Box>
  );
};

export default DxfViewer;
