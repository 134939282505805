import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// Цветовая палитра для приложения
const colorPalette = {
  // Основные цвета
  primary: {
    main: '#1976d2',
    light: '#42a5f5',
    dark: '#1565c0',
    contrastText: '#fff',
  },
  secondary: {
    main: '#dc004e',
    light: '#ff4081',
    dark: '#9a0036',
    contrastText: '#fff',
  },
  
  // Цвета для статусов
  status: {
    notStarted: '#808080', // Серый
    inProgress: '#FFA500', // Оранжевый
    delayed: '#FF0000',    // Красный
    completed: '#00FF00',  // Зеленый
  },
  
  // Цвета для типов сущностей
  entityTypes: {
    LINE: '#2196F3',       // Синий
    POLYLINE: '#FF9800',   // Оранжевый
    CIRCLE: '#4CAF50',     // Зеленый
    ARC: '#9C27B0',        // Фиолетовый
    ELLIPSE: '#009688',    // Бирюзовый
    TEXT: '#795548',       // Коричневый
    BLOCK: '#3F51B5',      // Индиго
    SPLINE: '#FF5722',     // Глубокий оранжевый
    DIMENSION: '#FFEB3B',  // Желтый
    HATCH: '#607D8B',      // Сине-серый
    FACE: '#8BC34A',       // Светло-зеленый
    POINT: '#E91E63',      // Розовый
  },
  
  // Нейтральные цвета
  neutral: {
    main: '#64748B',
    light: '#F8F9FA',
    medium: '#E2E8F0',
    dark: '#334155',
  },
  
  // Фоновые цвета
  background: {
    default: '#F8F9FA',
    paper: '#FFFFFF',
    dark: '#121212',
  },
  
  // Цвета интерфейса
  interface: {
    border: '#E0E0E0',
    divider: '#EEEEEE',
    hover: 'rgba(0, 0, 0, 0.04)',
    selected: 'rgba(25, 118, 210, 0.08)',
    focus: 'rgba(25, 118, 210, 0.12)',
  },
};

// Создание темы для светлого режима
export const lightTheme = responsiveFontSizes(createTheme({
  palette: {
    mode: 'light',
    primary: colorPalette.primary,
    secondary: colorPalette.secondary,
    background: {
      default: colorPalette.background.default,
      paper: colorPalette.background.paper,
    },
    divider: colorPalette.interface.divider,
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
  },
  
  // Стили типографики
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 500,
      textTransform: 'none',
    },
    caption: {
      fontSize: '0.75rem',
    },
    overline: {
      fontSize: '0.75rem',
      textTransform: 'uppercase',
      fontWeight: 500,
    },
  },
  
  // Компоненты со своими стилями
  components: {
    // Кастомные стили для компонентов Paper
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
        elevation1: {
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        },
        elevation2: {
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.05)',
        },
      },
    },
    
    // Кастомные стили для кнопок
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 500,
        },
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
          },
        },
        outlined: {
          borderWidth: 1.5,
        },
      },
    },
    
    // Кастомные стили для Tabs
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 40,
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
        },
      },
    },
    
    // Кастомные стили для Tab
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 40,
          padding: '10px 16px',
          fontWeight: 500,
          textTransform: 'none',
        },
      },
    },
    
    // Кастомные стили для карточек
    MuiCard: {
      styleOverrides: {
        root: {
          overflow: 'visible',
          backgroundImage: 'none',
          borderRadius: 12,
          transition: 'transform 0.2s, box-shadow 0.2s',
          '&:hover': {
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
    
    // Кастомные стили для CardContent
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 16,
          '&:last-child': {
            paddingBottom: 16,
          },
        },
      },
    },
    
    // Кастомные стили для progress bars
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          height: 8,
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
        },
        bar: {
          borderRadius: 4,
        },
      },
    },
    
    // Кастомные стили для Drawer
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRadius: 0,
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    
    // Кастомные стили для Chip
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          fontSize: '0.75rem',
          height: 24,
        },
      },
    },
    
    // Кастомные стили для формочек
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginBottom: 16,
        },
      },
    },
    
    // Кастомные стили для текстовых полей
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colorPalette.primary.light,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 2,
          },
        },
        notchedOutline: {
          borderColor: colorPalette.interface.border,
        },
      },
    },
    
    // Кастомные стили для Tooltips
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          fontSize: '0.75rem',
          padding: '8px 12px',
          borderRadius: 4,
        },
      },
    },
    
    // Кастомные стили для Alert
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          padding: '8px 16px',
        },
        icon: {
          opacity: 0.8,
        },
      },
    },
    
    // Кастомные стили для таблиц
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
          borderBottom: `1px solid ${colorPalette.interface.divider}`,
        },
        head: {
          fontWeight: 600,
          backgroundColor: colorPalette.background.default,
        },
      },
    },
    
    // Кастомные стили для списков
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          '&.Mui-selected': {
            backgroundColor: colorPalette.interface.selected,
          },
          '&.Mui-selected:hover': {
            backgroundColor: colorPalette.interface.focus,
          },
          '&:hover': {
            backgroundColor: colorPalette.interface.hover,
          },
        },
      },
    },
    
    // Новые стили для компонента ViewerToolbar
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderBottom: `1px solid ${colorPalette.interface.divider}`,
        },
      },
    },
  },
  
  // Настройки для DXF Viewer
  dxfViewer: {
    // Цвета для статусов
    status: colorPalette.status,
    
    // Цвета для типов сущностей
    entityTypes: colorPalette.entityTypes,
    
    // Стили для Canvas
    canvas: {
      background: '#F5F5F5',
      grid: {
        color: 'rgba(0, 0, 0, 0.1)',
        spacing: 10,
      },
      selection: {
        color: '#2196F3',
        width: 2,
        dashArray: [5, 5],
      },
    },
    
    // Стили для инструментов
    tools: {
      iconSize: 24,
      padding: 8,
      spacing: 4,
    },
    
    // Стили для информационной панели
    infoPanel: {
      width: 320,
      headerHeight: 56,
      tabHeight: 48,
    },
    
    // Стили для отрисовки сущностей
    entities: {
      defaultStrokeWidth: 1,
      selectedStrokeWidth: 2,
      hitAreaPadding: 5,
      textScale: 0.8,
    },
  },
}));

// Создание темы для темного режима
export const darkTheme = responsiveFontSizes(createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90CAF9',
      light: '#BBDEFB',
      dark: '#42A5F5',
      contrastText: '#000',
    },
    secondary: {
      main: '#F48FB1',
      light: '#F8BBD0',
      dark: '#EC407A',
      contrastText: '#000',
    },
    background: {
      default: '#121212',
      paper: '#1E1E1E',
    },
    divider: 'rgba(255, 255, 255, 0.12)',
    text: {
      primary: '#FFFFFF',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)',
    },
  },
  
  // Компоненты со своими стилями для темного режима
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          boxShadow: 'none',
        },
      },
    },
  },
  
  // Настройки для DXF Viewer в темном режиме
  dxfViewer: {
    // Цвета для статусов остаются теми же
    status: colorPalette.status,
    
    // Цвета для типов сущностей те же
    entityTypes: colorPalette.entityTypes,
    
    // Стили для Canvas в темном режиме
    canvas: {
      background: '#1A1A1A',
      grid: {
        color: 'rgba(255, 255, 255, 0.1)',
        spacing: 10,
      },
      selection: {
        color: '#90CAF9',
        width: 2,
        dashArray: [5, 5],
      },
    },
  },
}));

// Предустановленные стили для визуализации статусов
export const statusStyles = {
  not_started: {
    color: colorPalette.status.notStarted,
    backgroundColor: `${colorPalette.status.notStarted}22`, // С прозрачностью
    label: 'Not Started',
    icon: 'HourglassEmpty',
  },
  in_progress: {
    color: colorPalette.status.inProgress,
    backgroundColor: `${colorPalette.status.inProgress}22`,
    label: 'In Progress',
    icon: 'Pending',
  },
  delayed: {
    color: colorPalette.status.delayed,
    backgroundColor: `${colorPalette.status.delayed}22`,
    label: 'Delayed',
    icon: 'Error',
  },
  completed: {
    color: colorPalette.status.completed,
    backgroundColor: `${colorPalette.status.completed}22`,
    label: 'Completed',
    icon: 'CheckCircle',
  },
};

// Предустановленные стили для визуализации типов сущностей
export const entityTypeStyles = {
  LINE: {
    color: colorPalette.entityTypes.LINE,
    icon: 'LinearScale',
    label: 'Line',
  },
  POLYLINE: {
    color: colorPalette.entityTypes.POLYLINE,
    icon: 'Timeline',
    label: 'Polyline',
  },
  CIRCLE: {
    color: colorPalette.entityTypes.CIRCLE,
    icon: 'RadioButtonUnchecked',
    label: 'Circle',
  },
  ARC: {
    color: colorPalette.entityTypes.ARC,
    icon: 'Architecture',
    label: 'Arc',
  },
  ELLIPSE: {
    color: colorPalette.entityTypes.ELLIPSE,
    icon: 'Lens',
    label: 'Ellipse',
  },
  TEXT: {
    color: colorPalette.entityTypes.TEXT,
    icon: 'TextFields',
    label: 'Text',
  },
  BLOCK: {
    color: colorPalette.entityTypes.BLOCK,
    icon: 'ViewModule',
    label: 'Block',
  },
  SPLINE: {
    color: colorPalette.entityTypes.SPLINE,
    icon: 'ShowChart',
    label: 'Spline',
  },
  DIMENSION: {
    color: colorPalette.entityTypes.DIMENSION,
    icon: 'Straighten',
    label: 'Dimension',
  },
  HATCH: {
    color: colorPalette.entityTypes.HATCH,
    icon: 'Texture',
    label: 'Hatch',
  },
  FACE: {
    color: colorPalette.entityTypes.FACE,
    icon: 'Widgets',
    label: 'Face',
  },
  POINT: {
    color: colorPalette.entityTypes.POINT,
    icon: 'FiberManualRecord',
    label: 'Point',
  },
};

// Экспорт текущей тему по умолчанию
export default lightTheme;
