import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { 
  Box, 
  Button, 
  Card, 
  CardActionArea,
  CardContent, 
  Container, 
  Divider, 
  Grid, 
  Paper, 
  Typography,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { 
  Upload as UploadIcon,
  ShowChart as ChartIcon,
  List as ListIcon,
  Settings as SettingsIcon,
  Architecture as ArchitectureIcon
} from '@mui/icons-material';
import { useAppContext } from '../context/AppContext';
import { checkHealth } from '../services/api';

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { drawings, loading } = useAppContext();
  const [serverStatus, setServerStatus] = useState({ status: 'checking' });
  
  // Check server health
  useEffect(() => {
    const checkServerStatus = async () => {
      try {
        const status = await checkHealth();
        setServerStatus({
          status: 'online',
          timestamp: status.timestamp
        });
      } catch (error) {
        setServerStatus({ status: 'offline' });
      }
    };
    
    checkServerStatus();
  }, []);
  
  // Get recent drawings
  const recentDrawings = drawings?.slice(0, 3) || [];
  
  // Calculate stats 
  const totalDrawings = drawings?.length || 0;
  const completedDrawings = drawings?.filter(d => (d.progress || 0) >= 100).length || 0;
  const inProgressDrawings = drawings?.filter(d => (d.progress || 0) > 0 && (d.progress || 0) < 100).length || 0;
  
  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      {/* Hero Section */}
      <Paper 
        sx={{ 
          p: { xs: 3, md: 6 }, 
          mb: 4, 
          borderRadius: 2,
          background: 'linear-gradient(120deg, #1976d2 0%, #2196f3 100%)',
          color: 'white',
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <Box sx={{ position: 'relative', zIndex: 1 }}>
          <Typography 
            variant="h3" 
            component="h1" 
            gutterBottom
            sx={{ fontWeight: 600 }}
          >
            DXF Viewer & Management
          </Typography>
          <Typography variant="h6" paragraph sx={{ maxWidth: 600, mb: 4 }}>
            Upload, visualize, and manage your DXF files with tools for progress tracking, 
            cost estimation, and collaborative project management.
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <Button 
                variant="contained" 
                color="secondary" 
                size="large"
                component={RouterLink}
                to="/upload"
                startIcon={<UploadIcon />}
              >
                Upload DXF
              </Button>
            </Grid>
            <Grid item>
              <Button 
                variant="outlined" 
                sx={{ 
                  color: 'white', 
                  borderColor: 'white',
                  '&:hover': {
                    borderColor: 'white',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)'
                  }
                }}
                size="large"
                component={RouterLink}
                to="/drawings"
                startIcon={<ListIcon />}
              >
                View Drawings
              </Button>
            </Grid>
          </Grid>
        </Box>
        
        {/* Decorative background elements */}
        <Box 
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: { xs: '150px', md: '300px' },
            height: '100%',
            opacity: 0.2,
            background: `url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0 L50 0 L50 50 L0 50 Z' fill='white'/%3E%3Cpath d='M50 0 L100 0 L100 50 L50 50 Z' fill='none' stroke='white'/%3E%3Cpath d='M0 50 L50 50 L50 100 L0 100 Z' fill='none' stroke='white'/%3E%3Cpath d='M50 50 L100 50 L100 100 L50 100 Z' fill='white'/%3E%3C/svg%3E")`,
            backgroundRepeat: 'repeat',
            zIndex: 0
          }}
        />
      </Paper>
      
      {/* Quick Stats */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={4}>
          <Paper 
            sx={{ 
              p: 3, 
              textAlign: 'center',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              background: 'linear-gradient(to bottom right, #e3f2fd, #bbdefb)'
            }}
          >
            <Typography variant="h3" component="div" sx={{ fontWeight: 'bold', color: '#1976d2' }}>
              {totalDrawings}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Total Drawings
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper 
            sx={{ 
              p: 3, 
              textAlign: 'center',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              background: 'linear-gradient(to bottom right, #e8f5e9, #c8e6c9)'
            }}
          >
            <Typography variant="h3" component="div" sx={{ fontWeight: 'bold', color: '#43a047' }}>
              {completedDrawings}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Completed
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Paper 
            sx={{ 
              p: 3, 
              textAlign: 'center', 
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              background: 'linear-gradient(to bottom right, #fff8e1, #ffecb3)'
            }}
          >
            <Typography variant="h3" component="div" sx={{ fontWeight: 'bold', color: '#ff9800' }}>
              {inProgressDrawings}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              In Progress
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      
      {/* Main Content Sections */}
      <Grid container spacing={4}>
        {/* Recent Drawings */}
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 3, height: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h5" component="h2">
                Recent Drawings
              </Typography>
              <Button 
                component={RouterLink} 
                to="/drawings" 
                variant="text"
                endIcon={<ListIcon />}
              >
                View All
              </Button>
            </Box>
            <Divider sx={{ mb: 2 }} />
            
            {loading ? (
              <Typography variant="body2" color="textSecondary">
                Loading recent drawings...
              </Typography>
            ) : recentDrawings.length === 0 ? (
              <Box sx={{ py: 2, textAlign: 'center' }}>
                <Typography variant="body1" color="textSecondary" gutterBottom>
                  No drawings found
                </Typography>
                <Button 
                  variant="contained" 
                  color="primary"
                  component={RouterLink}
                  to="/upload"
                  startIcon={<UploadIcon />}
                  sx={{ mt: 1 }}
                >
                  Upload Your First Drawing
                </Button>
              </Box>
            ) : (
              <Grid container spacing={2}>
                {recentDrawings.map((drawing) => (
                  <Grid item xs={12} sm={6} md={4} key={drawing.file_id}>
                    <Card>
                      <CardActionArea 
                        component={RouterLink}
                        to={`/viewer/${drawing.file_id}`}
                      >
                        <Box sx={{ height: 8, width: '100%', bgcolor: '#e0e0e0', position: 'relative' }}>
                          <Box 
                            sx={{ 
                              position: 'absolute', 
                              top: 0, 
                              left: 0, 
                              height: '100%', 
                              width: `${drawing.progress || Math.floor(Math.random() * 100)}%`,
                              bgcolor: (drawing.progress || 0) < 30 ? '#f44336' : 
                                     (drawing.progress || 0) < 70 ? '#ff9800' : '#4caf50'
                            }}
                          />
                        </Box>
                        <CardContent>
                          <Typography variant="subtitle1" noWrap>
                            {drawing.name || drawing.filename}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" noWrap>
                            Project: {drawing.project || 'Unknown'}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
          </Paper>
        </Grid>
        
        {/* Quick Actions & System Info */}
        <Grid item xs={12} md={4}>
          <Grid container direction="column" spacing={3} height="100%">
            {/* Quick Actions */}
            <Grid item>
              <Paper sx={{ p: 3 }}>
                <Typography variant="h5" component="h2" gutterBottom>
                  Quick Actions
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="outlined"
                      component={RouterLink}
                      to="/upload"
                      sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}
                    >
                      <UploadIcon sx={{ fontSize: 32, mb: 1 }} />
                      <Typography variant="button">Upload</Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="outlined"
                      component={RouterLink}
                      to="/drawings"
                      sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}
                    >
                      <ListIcon sx={{ fontSize: 32, mb: 1 }} />
                      <Typography variant="button">Drawings</Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="outlined"
                      component={RouterLink}
                      to="/reports"
                      sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}
                    >
                      <ChartIcon sx={{ fontSize: 32, mb: 1 }} />
                      <Typography variant="button">Reports</Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="outlined"
                      component={RouterLink}
                      to="/settings"
                      sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}
                    >
                      <SettingsIcon sx={{ fontSize: 32, mb: 1 }} />
                      <Typography variant="button">Settings</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            
            {/* System Status */}
            <Grid item>
              <Paper sx={{ p: 3 }}>
                <Typography variant="h5" component="h2" gutterBottom>
                  System Status
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      borderRadius: '50%',
                      bgcolor: serverStatus.status === 'online' ? 'success.main' : 
                              serverStatus.status === 'checking' ? 'warning.main' : 'error.main',
                      mr: 1
                    }}
                  />
                  <Typography variant="body2">
                    Server: {serverStatus.status === 'online' ? 'Online' : 
                             serverStatus.status === 'checking' ? 'Checking...' : 'Offline'}
                  </Typography>
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      borderRadius: '50%',
                      bgcolor: 'success.main',
                      mr: 1
                    }}
                  />
                  <Typography variant="body2">
                    API Version: v0.1.0
                  </Typography>
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box
                    sx={{
                      width: 10,
                      height: 10,
                      borderRadius: '50%',
                      bgcolor: 'success.main',
                      mr: 1
                    }}
                  />
                  <Typography variant="body2">
                    Database: Connected
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            
            {/* About App */}
            <Grid item sx={{ flexGrow: 1 }}>
              <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <ArchitectureIcon sx={{ mr: 1, color: 'primary.main' }} />
                  <Typography variant="h5" component="h2">
                    About DXF Viewer
                  </Typography>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <Typography variant="body2" paragraph>
                  DXF Viewer is a web application for architects, engineers, and construction professionals
                  to visualize and manage DXF drawings with real-time progress tracking.
                </Typography>
                <Typography variant="body2" paragraph>
                  Mark elements with different statuses, track project progress, and 
                  collaborate with your team.
                </Typography>
                <Box sx={{ mt: 'auto', pt: 2 }}>
                  <Typography variant="caption" color="textSecondary">
                    Version: 0.1.0
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Home;
