import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Divider,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  useTheme
} from '@mui/material';
import { 
  Circle as CircleIcon,
  Straighten,
  SquareFoot,
  LayersTwoTone,
  ShowChart,
  LinearScale,
  RadioButtonUnchecked,
  Architecture,
  RoundedCorner,
  TextFields,
  Texture,
  ViewInAr,
  Lens,
  FiberManualRecord
} from '@mui/icons-material';

// Карта иконок для разных типов сущностей
const ENTITY_TYPE_ICONS = {
  LINE: LinearScale,
  POLYLINE: ShowChart,
  CIRCLE: RadioButtonUnchecked,
  ARC: Architecture,
  ELLIPSE: Lens,
  TEXT: TextFields,
  BLOCK: ViewInAr,
  SPLINE: RoundedCorner,
  HATCH: Texture,
  POINT: FiberManualRecord,
  DIMENSION: Straighten,
  FACE: ViewInAr,
  SOLID: ViewInAr,
  DEFAULT: CircleIcon
};

/**
 * Компонент для отображения информации о выбранной сущности
 */
const EntityInfoPanel = ({
  entity,
  onStatusChange,
  onColorChange,
  statusOptions = {
    not_started: { label: 'Not Started', color: '#808080' },
    in_progress: { label: 'In Progress', color: '#FFA500' },
    delayed: { label: 'Delayed', color: '#FF0000' },
    completed: { label: 'Completed', color: '#00FF00' }
  }
}) => {
  const theme = useTheme();
  
  if (!entity) {
    return (
      <Box p={2}>
        <Typography variant="body2" color="text.secondary" align="center">
          No entity selected. Click on an element to see its details.
        </Typography>
      </Box>
    );
  }
  
  // Получение иконки для типа сущности
  const EntityTypeIcon = ENTITY_TYPE_ICONS[entity.type] || ENTITY_TYPE_ICONS.DEFAULT;
  
  // Форматирование значений для отображения
  const formatValue = (value) => {
    if (typeof value === 'number') {
      return value.toFixed(2);
    }
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    if (value === null || value === undefined) {
      return '—';
    }
    return String(value);
  };
  
  // Рендер информации о размерах в зависимости от типа сущности
  const renderDimensionInfo = () => {
    if (!entity.metrics) return null;
    
    return (
      <Box mt={2}>
        <Typography variant="subtitle2" gutterBottom>
          Dimensions
        </Typography>
        
        <TableContainer component={Paper} variant="outlined">
          <Table size="small">
            <TableBody>
              {entity.metrics.length !== undefined && (
                <TableRow>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Straighten fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />
                      Length
                    </Box>
                  </TableCell>
                  <TableCell align="right">{formatValue(entity.metrics.length)}</TableCell>
                </TableRow>
              )}
              
              {entity.metrics.area !== undefined && (
                <TableRow>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <SquareFoot fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />
                      Area
                    </Box>
                  </TableCell>
                  <TableCell align="right">{formatValue(entity.metrics.area)}</TableCell>
                </TableRow>
              )}
              
              {entity.type === 'CIRCLE' && entity.radius !== undefined && (
                <TableRow>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <RadioButtonUnchecked fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />
                      Radius
                    </Box>
                  </TableCell>
                  <TableCell align="right">{formatValue(entity.radius)}</TableCell>
                </TableRow>
              )}
              
              {entity.type === 'ELLIPSE' && (
                <>
                  {entity.major_radius && (
                    <TableRow>
                      <TableCell>Major Radius</TableCell>
                      <TableCell align="right">{formatValue(entity.major_radius)}</TableCell>
                    </TableRow>
                  )}
                  {entity.minor_radius && (
                    <TableRow>
                      <TableCell>Minor Radius</TableCell>
                      <TableCell align="right">{formatValue(entity.minor_radius)}</TableCell>
                    </TableRow>
                  )}
                  {entity.rotation !== undefined && (
                    <TableRow>
                      <TableCell>Rotation</TableCell>
                      <TableCell align="right">{formatValue(entity.rotation)}°</TableCell>
                    </TableRow>
                  )}
                </>
              )}
              
              {entity.type === 'ARC' && (
                <>
                  {entity.radius !== undefined && (
                    <TableRow>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <RadioButtonUnchecked fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />
                          Radius
                        </Box>
                      </TableCell>
                      <TableCell align="right">{formatValue(entity.radius)}</TableCell>
                    </TableRow>
                  )}
                  {entity.start_angle !== undefined && (
                    <TableRow>
                      <TableCell>Start Angle</TableCell>
                      <TableCell align="right">{formatValue(entity.start_angle)}°</TableCell>
                    </TableRow>
                  )}
                  {entity.end_angle !== undefined && (
                    <TableRow>
                      <TableCell>End Angle</TableCell>
                      <TableCell align="right">{formatValue(entity.end_angle)}°</TableCell>
                    </TableRow>
                  )}
                </>
              )}
              
              {entity.type === 'TEXT' && (
                <>
                  {entity.height !== undefined && (
                    <TableRow>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <TextFields fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />
                          Height
                        </Box>
                      </TableCell>
                      <TableCell align="right">{formatValue(entity.height)}</TableCell>
                    </TableRow>
                  )}
                  {entity.rotation !== undefined && (
                    <TableRow>
                      <TableCell>Rotation</TableCell>
                      <TableCell align="right">{formatValue(entity.rotation)}°</TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };
  
  // Рендер специфичной для типа информации
  const renderTypeSpecificInfo = () => {
    switch (entity.type) {
      case 'TEXT':
        return (
          <Box mt={2}>
            <Typography variant="subtitle2" gutterBottom>
              Text Content
            </Typography>
            <Paper variant="outlined" sx={{ p: 1.5, bgcolor: 'background.default' }}>
              <Typography variant="body2">
                {entity.text || '(empty text)'}
              </Typography>
            </Paper>
          </Box>
        );
        
      case 'BLOCK':
        return (
          <Box mt={2}>
            <Typography variant="subtitle2" gutterBottom>
              Block Information
            </Typography>
            <TableContainer component={Paper} variant="outlined">
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>Block Name</TableCell>
                    <TableCell>{entity.name || 'Unnamed Block'}</TableCell>
                  </TableRow>
                  {entity.entity_count !== undefined && (
                    <TableRow>
                      <TableCell>Entities</TableCell>
                      <TableCell>{entity.entity_count}</TableCell>
                    </TableRow>
                  )}
                  {entity.entities && (
                    <TableRow>
                      <TableCell>Entities Count</TableCell>
                      <TableCell>{entity.entities.length}</TableCell>
                    </TableRow>
                  )}
                  {entity.rotation !== undefined && (
                    <TableRow>
                      <TableCell>Rotation</TableCell>
                      <TableCell>{formatValue(entity.rotation)}°</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        );
        
      case 'SPLINE':
        return (
          <Box mt={2}>
            <Typography variant="subtitle2" gutterBottom>
              Spline Information
            </Typography>
            <TableContainer component={Paper} variant="outlined">
              <Table size="small">
                <TableBody>
                  {entity.degree !== undefined && (
                    <TableRow>
                      <TableCell>Degree</TableCell>
                      <TableCell>{entity.degree}</TableCell>
                    </TableRow>
                  )}
                  {entity.closed !== undefined && (
                    <TableRow>
                      <TableCell>Closed</TableCell>
                      <TableCell>{entity.closed ? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                  )}
                  {entity.control_points && (
                    <TableRow>
                      <TableCell>Control Points</TableCell>
                      <TableCell>{entity.control_points.length}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        );
        
      case 'HATCH':
        return (
          <Box mt={2}>
            <Typography variant="subtitle2" gutterBottom>
              Hatch Information
            </Typography>
            <TableContainer component={Paper} variant="outlined">
              <Table size="small">
                <TableBody>
                  {entity.pattern_name && (
                    <TableRow>
                      <TableCell>Pattern</TableCell>
                      <TableCell>{entity.pattern_name}</TableCell>
                    </TableRow>
                  )}
                  {entity.solid_fill !== undefined && (
                    <TableRow>
                      <TableCell>Solid Fill</TableCell>
                      <TableCell>{entity.solid_fill ? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                  )}
                  {entity.pattern_angle !== undefined && (
                    <TableRow>
                      <TableCell>Angle</TableCell>
                      <TableCell>{formatValue(entity.pattern_angle)}°</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        );
      
      default:
        return null;
    }
  };
  
  // Рендер информации о положении
  const renderPositionInfo = () => {
    let position = null;
    
    if (entity.center) {
      position = entity.center;
    } else if (entity.position) {
      position = entity.position;
    } else if (entity.insert_point) {
      position = entity.insert_point;
    } else if (entity.points && entity.points.length > 0) {
      position = entity.points[0];
    }
    
    if (!position) return null;
    
    return (
      <Grid container spacing={1} sx={{ mt: 1 }}>
        <Grid item xs={4}>
          <Tooltip title="X Coordinate">
            <Paper variant="outlined" sx={{ p: 1, textAlign: 'center' }}>
              <Typography variant="caption" color="text.secondary">X</Typography>
              <Typography variant="body2" fontWeight="medium">
                {formatValue(position.x)}
              </Typography>
            </Paper>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Tooltip title="Y Coordinate">
            <Paper variant="outlined" sx={{ p: 1, textAlign: 'center' }}>
              <Typography variant="caption" color="text.secondary">Y</Typography>
              <Typography variant="body2" fontWeight="medium">
                {formatValue(position.y)}
              </Typography>
            </Paper>
          </Tooltip>
        </Grid>
        <Grid item xs={4}>
          <Tooltip title="Z Coordinate">
            <Paper variant="outlined" sx={{ p: 1, textAlign: 'center' }}>
              <Typography variant="caption" color="text.secondary">Z</Typography>
              <Typography variant="body2" fontWeight="medium">
                {formatValue(position.z)}
              </Typography>
            </Paper>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };
  
  return (
    <Box p={2}>
      {/* Заголовок с информацией о типе */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box 
          sx={{ 
            width: 36, 
            height: 36, 
            borderRadius: 1, 
            bgcolor: 'action.hover',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 1.5
          }}
        >
          <EntityTypeIcon 
            color="primary" 
            fontSize="medium" 
          />
        </Box>
        <Box>
          <Typography variant="subtitle1">
            {entity.type} Entity
          </Typography>
          <Typography variant="caption" color="text.secondary">
            ID: {entity.id}
          </Typography>
        </Box>
      </Box>
      
      {/* Основная информация */}
      <Card variant="outlined" sx={{ mt: 2 }}>
        <CardContent sx={{ pb: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            General Information
          </Typography>
          
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
            <LayersTwoTone fontSize="small" sx={{ mr: 1, color: 'text.secondary' }} />
            <Typography variant="body2">
              Layer: 
              <Chip 
                label={entity.layer} 
                size="small" 
                sx={{ ml: 1 }}
              />
            </Typography>
          </Box>
          
          {renderPositionInfo()}
          
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth size="small">
              <InputLabel>Status</InputLabel>
              <Select
                value={entity.status || 'not_started'}
                label="Status"
                onChange={(e) => onStatusChange && onStatusChange(entity.id, e.target.value)}
              >
                {Object.entries(statusOptions).map(([value, option]) => (
                  <MenuItem key={value} value={value}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box 
                        sx={{ 
                          width: 16, 
                          height: 16, 
                          borderRadius: '50%', 
                          bgcolor: option.color,
                          mr: 1
                        }} 
                      />
                      {option.label}
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </CardContent>
      </Card>
      
      {/* Информация о размерах */}
      {renderDimensionInfo()}
      
      {/* Специфичная для типа информация */}
      {renderTypeSpecificInfo()}
    </Box>
  );
};

EntityInfoPanel.propTypes = {
  entity: PropTypes.object,
  onStatusChange: PropTypes.func,
  onColorChange: PropTypes.func,
  statusOptions: PropTypes.object
};

export default EntityInfoPanel;
