import React from 'react';
import PropTypes from 'prop-types';
import { 
  Box, 
  Paper, 
  Divider, 
  IconButton, 
  Tooltip,

  Badge,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme
} from '@mui/material';
import {
  // Основные инструменты
  ZoomIn,
  ZoomOut,
  PanTool,
  Refresh,
  Layers,
  BrushOutlined,
  ViewInAr,
  Info,
  Save,
  
  // Дополнительные инструменты
  ContentCopy,
  ContentCut,
  ContentPaste,
  RotateLeft,
  RotateRight,
  SwapHoriz, // Заменено с FlipHorizontal
  SwapVert,  // Заменено с FlipVertical
  CropFree,
  GridOn,
  Settings,
  BorderStyle,
  Undo,
  Redo,
  MoreVert,
  
  // Статусы
  CheckCircle,
  Pending,
  Error,
  HourglassEmpty
} from '@mui/icons-material';

// Константы для статусов
const STATUS_ICONS = {
  not_started: HourglassEmpty,
  in_progress: Pending,
  delayed: Error,
  completed: CheckCircle
};

/**
 * Компонент панели инструментов для DXF Viewer
 */
const ViewerToolbar = ({
  drawingName = 'Untitled Drawing',
  onZoomIn,
  onZoomOut,
  onPan,
  onReset,
  onToggleLayers,
  onToggleSelection,
  onToggle3DMode,
  onShowInfo,
  onExport,
  // Дополнительные обработчики
  onCopy,
  onCut,
  onPaste,
  onRotateLeft,
  onRotateRight,
  onFlipHorizontal,
  onFlipVertical,
  onFitToView,
  onToggleGrid,
  onOpenSettings,
  onShowDimensions,
  onUndo,
  onRedo,
  onFilterByStatus, // Добавлен недостающий проп
  // Текущие состояния
  isPanning = false,
  isSelectionMode = false,
  is3DMode = false,
  showGrid = true,
  showDimensions = true,
  isEditingEnabled = true,
  // Выбранные элементы
  selectedElements = [],
  // Состояния фильтрации
  filterByStatus = null,
  // Метрики
  metrics = null,
  // Отображение меню
  showAdvancedTools = true,
  compact = false
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [statusAnchorEl, setStatusAnchorEl] = React.useState(null);
  
  // Состояния меню
  const isMenuOpen = Boolean(anchorEl);
  const isStatusMenuOpen = Boolean(statusAnchorEl);
  
  // Открытие меню дополнительных инструментов
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  // Закрытие меню дополнительных инструментов
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  
  // Открытие меню фильтрации по статусу
  const handleOpenStatusMenu = (event) => {
    setStatusAnchorEl(event.currentTarget);
  };
  
  // Закрытие меню фильтрации по статусу
  const handleCloseStatusMenu = () => {
    setStatusAnchorEl(null);
  };
  
  // Выбор статуса для фильтрации
  const handleSelectStatus = (status) => {
    if (onFilterByStatus) {
      onFilterByStatus(status);
    }
    handleCloseStatusMenu();
  };
  
  // Цвета статусов из темы
  const statusColors = theme.dxfViewer?.status || {
    not_started: '#808080',
    in_progress: '#FFA500',
    delayed: '#FF0000',
    completed: '#00FF00'
  };
  
  // Названия статусов
  const statusLabels = {
    not_started: 'Not Started',
    in_progress: 'In Progress',
    delayed: 'Delayed',
    completed: 'Completed'
  };
  
  // Отображение метрик прогресса
  const renderProgressMetrics = () => {
    if (!metrics) return null;
    
    const progress = metrics.completedPercentage || 0;
    
    return (
      <Box sx={{ mx: 2, display: 'flex', alignItems: 'center' }}>
        <Tooltip title={`${progress.toFixed(1)}% Complete`}>
          <Badge 
            color={
              progress === 100 ? 'success' : 
              progress > 70 ? 'info' :
              progress > 30 ? 'warning' : 'error'
            }
            badgeContent={`${Math.round(progress)}%`}
            sx={{ '& .MuiBadge-badge': { fontSize: '10px', height: '16px', minWidth: '16px' } }}
          >
            <IconButton
              size="small"
              onClick={handleOpenStatusMenu}
            >
              {progress === 100 ? (
                <CheckCircle color="success" />
              ) : progress > 70 ? (
                <Pending color="info" />
              ) : progress > 30 ? (
                <Pending color="warning" />
              ) : (
                <HourglassEmpty color="error" />
              )}
            </IconButton>
          </Badge>
        </Tooltip>
      </Box>
    );
  };
  
  return (
    <Paper elevation={1} sx={{ p: 0.5, mb: compact ? 0.5 : 1 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* Левая часть - название и метрики */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography 
            variant={compact ? "subtitle1" : "h6"} 
            component="div" 
            noWrap
            sx={{ maxWidth: { xs: 120, sm: 200, md: 'none' } }}
          >
            {drawingName}
          </Typography>
          
          {!compact && renderProgressMetrics()}
        </Box>
        
        {/* Правая часть - инструменты */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: compact ? 0.5 : 1 }}>
          {/* Основные инструменты */}
          <Tooltip title="Zoom In">
            <IconButton size={compact ? "small" : "medium"} onClick={onZoomIn}>
              <ZoomIn />
            </IconButton>
          </Tooltip>
          
          <Tooltip title="Zoom Out">
            <IconButton size={compact ? "small" : "medium"} onClick={onZoomOut}>
              <ZoomOut />
            </IconButton>
          </Tooltip>
          
          <Tooltip title="Pan Tool">
            <IconButton 
              size={compact ? "small" : "medium"}
              color={isPanning ? 'primary' : 'default'}
              onClick={onPan}
            >
              <PanTool />
            </IconButton>
          </Tooltip>
          
          <Tooltip title="Reset View">
            <IconButton size={compact ? "small" : "medium"} onClick={onReset}>
              <Refresh />
            </IconButton>
          </Tooltip>
          
          {!compact && (
            <>
              <Tooltip title="Fit to View">
                <IconButton size="medium" onClick={onFitToView}>
                  <CropFree />
                </IconButton>
              </Tooltip>
              
              <Divider orientation="vertical" flexItem sx={{ mx: 0.5 }} />
            </>
          )}
          
          <Tooltip title="Toggle Layers">
            <IconButton size={compact ? "small" : "medium"} onClick={onToggleLayers}>
              <Layers />
            </IconButton>
          </Tooltip>
          
          <Tooltip title={isSelectionMode ? "Exit Selection Mode" : "Enter Selection Mode"}>
            <IconButton 
              size={compact ? "small" : "medium"}
              color={isSelectionMode ? 'primary' : 'default'}
              onClick={onToggleSelection}
            >
              <BrushOutlined />
            </IconButton>
          </Tooltip>
          
          {!compact && (
            <Tooltip title={is3DMode ? "Switch to 2D View" : "Switch to 3D View"}>
              <IconButton 
                size="medium"
                color={is3DMode ? 'primary' : 'default'}
                onClick={onToggle3DMode}
              >
                <ViewInAr />
              </IconButton>
            </Tooltip>
          )}
          
          {!compact && (
            <Tooltip title="Toggle Grid">
              <IconButton 
                size="medium"
                color={showGrid ? 'primary' : 'default'}
                onClick={onToggleGrid}
              >
                <GridOn />
              </IconButton>
            </Tooltip>
          )}
          
          {!compact && (
            <Tooltip title="Show Info">
              <IconButton size="medium" onClick={onShowInfo}>
                <Info />
              </IconButton>
            </Tooltip>
          )}
          
          {isEditingEnabled && !compact && (
            <>
              <Divider orientation="vertical" flexItem sx={{ mx: 0.5 }} />
              
              <Tooltip title="Undo">
                <IconButton size="medium" onClick={onUndo}>
                  <Undo />
                </IconButton>
              </Tooltip>
              
              <Tooltip title="Redo">
                <IconButton size="medium" onClick={onRedo}>
                  <Redo />
                </IconButton>
              </Tooltip>
            </>
          )}
          
          {selectedElements.length > 0 && !compact && (
            <>
              <Divider orientation="vertical" flexItem sx={{ mx: 0.5 }} />
              
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2" sx={{ mr: 1 }}>
                  {selectedElements.length} selected
                </Typography>
                
                <Tooltip title="Copy">
                  <IconButton size="small" onClick={onCopy}>
                    <ContentCopy fontSize="small" />
                  </IconButton>
                </Tooltip>
                
                {isEditingEnabled && (
                  <Tooltip title="Cut">
                    <IconButton size="small" onClick={onCut}>
                      <ContentCut fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
                
                {isEditingEnabled && (
                  <Tooltip title="Rotate Left">
                    <IconButton size="small" onClick={onRotateLeft}>
                      <RotateLeft fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
                
                {isEditingEnabled && (
                  <Tooltip title="Rotate Right">
                    <IconButton size="small" onClick={onRotateRight}>
                      <RotateRight fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </>
          )}
          
          <Tooltip title="Export Drawing">
            <IconButton size={compact ? "small" : "medium"} onClick={onExport}>
              <Save />
            </IconButton>
          </Tooltip>
          
          {showAdvancedTools && (
            <Tooltip title="More Tools">
              <IconButton
                size={compact ? "small" : "medium"}
                onClick={handleOpenMenu}
                aria-label="more tools"
                aria-controls="toolbar-menu"
                aria-expanded={isMenuOpen ? 'true' : undefined}
                aria-haspopup="true"
              >
                <MoreVert />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      
      {/* Выпадающее меню дополнительных инструментов */}
      <Menu
        id="toolbar-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'toolbar-menu-button',
        }}
      >
        {isEditingEnabled && (
          <>
            <MenuItem onClick={() => { onCopy(); handleCloseMenu(); }}>
              <ListItemIcon>
                <ContentCopy fontSize="small" />
              </ListItemIcon>
              <ListItemText>Copy</ListItemText>
            </MenuItem>
            
            <MenuItem onClick={() => { onCut(); handleCloseMenu(); }}>
              <ListItemIcon>
                <ContentCut fontSize="small" />
              </ListItemIcon>
              <ListItemText>Cut</ListItemText>
            </MenuItem>
            
            <MenuItem onClick={() => { onPaste(); handleCloseMenu(); }}>
              <ListItemIcon>
                <ContentPaste fontSize="small" />
              </ListItemIcon>
              <ListItemText>Paste</ListItemText>
            </MenuItem>
            
            <Divider />
          </>
        )}
        
        <MenuItem onClick={() => { onRotateLeft(); handleCloseMenu(); }}>
          <ListItemIcon>
            <RotateLeft fontSize="small" />
          </ListItemIcon>
          <ListItemText>Rotate Left</ListItemText>
        </MenuItem>
        
        <MenuItem onClick={() => { onRotateRight(); handleCloseMenu(); }}>
          <ListItemIcon>
            <RotateRight fontSize="small" />
          </ListItemIcon>
          <ListItemText>Rotate Right</ListItemText>
        </MenuItem>
        
        <MenuItem onClick={() => { onFlipHorizontal(); handleCloseMenu(); }}>
          <ListItemIcon>
            <SwapHoriz fontSize="small" />
          </ListItemIcon>
          <ListItemText>Flip Horizontal</ListItemText>
        </MenuItem>
        
        <MenuItem onClick={() => { onFlipVertical(); handleCloseMenu(); }}>
          <ListItemIcon>
            <SwapVert fontSize="small" />
          </ListItemIcon>
          <ListItemText>Flip Vertical</ListItemText>
        </MenuItem>
        
        <Divider />
        
        <MenuItem onClick={() => { onFitToView(); handleCloseMenu(); }}>
          <ListItemIcon>
            <CropFree fontSize="small" />
          </ListItemIcon>
          <ListItemText>Fit to View</ListItemText>
        </MenuItem>
        
        <MenuItem onClick={() => { onToggleGrid(); handleCloseMenu(); }}>
          <ListItemIcon>
            <GridOn fontSize="small" color={showGrid ? 'primary' : 'inherit'} />
          </ListItemIcon>
          <ListItemText>Toggle Grid</ListItemText>
        </MenuItem>
        
        <MenuItem onClick={() => { onShowDimensions(); handleCloseMenu(); }}>
          <ListItemIcon>
            <BorderStyle fontSize="small" color={showDimensions ? 'primary' : 'inherit'} />
          </ListItemIcon>
          <ListItemText>Show Dimensions</ListItemText>
        </MenuItem>
        
        <Divider />
        
        <MenuItem onClick={() => { onOpenSettings(); handleCloseMenu(); }}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <ListItemText>Settings</ListItemText>
        </MenuItem>
      </Menu>
      
      {/* Меню фильтрации по статусу */}
      <Menu
        id="status-menu"
        anchorEl={statusAnchorEl}
        open={isStatusMenuOpen}
        onClose={handleCloseStatusMenu}
        MenuListProps={{
          'aria-labelledby': 'status-menu-button',
        }}
      >
        <MenuItem disabled>
          <Typography variant="body2" color="text.secondary">
            Filter by Status
          </Typography>
        </MenuItem>
        
        <Divider />
        
        {Object.entries(statusLabels).map(([status, label]) => {
          const StatusIcon = STATUS_ICONS[status];
          const isActive = filterByStatus === status;
          
          return (
            <MenuItem 
              key={status} 
              onClick={() => handleSelectStatus(status)}
              selected={isActive}
            >
              <ListItemIcon>
                <StatusIcon 
                  fontSize="small" 
                  style={{ color: statusColors[status] }}
                />
              </ListItemIcon>
              <ListItemText>{label}</ListItemText>
            </MenuItem>
          );
        })}
        
        <Divider />
        
        <MenuItem onClick={() => handleSelectStatus(null)}>
          <ListItemText>Clear Filter</ListItemText>
        </MenuItem>
      </Menu>
    </Paper>
  );
};

ViewerToolbar.propTypes = {
  drawingName: PropTypes.string,
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  onPan: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onToggleLayers: PropTypes.func.isRequired,
  onToggleSelection: PropTypes.func.isRequired,
  onToggle3DMode: PropTypes.func,
  onShowInfo: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onCopy: PropTypes.func,
  onCut: PropTypes.func,
  onPaste: PropTypes.func,
  onRotateLeft: PropTypes.func,
  onRotateRight: PropTypes.func,
  onFlipHorizontal: PropTypes.func,
  onFlipVertical: PropTypes.func,
  onFitToView: PropTypes.func,
  onToggleGrid: PropTypes.func,
  onOpenSettings: PropTypes.func,
  onShowDimensions: PropTypes.func,
  onUndo: PropTypes.func,
  onRedo: PropTypes.func,
  onFilterByStatus: PropTypes.func,
  isPanning: PropTypes.bool,
  isSelectionMode: PropTypes.bool,
  is3DMode: PropTypes.bool,
  showGrid: PropTypes.bool,
  showDimensions: PropTypes.bool,
  isEditingEnabled: PropTypes.bool,
  selectedElements: PropTypes.array,
  filterByStatus: PropTypes.string,
  metrics: PropTypes.object,
  showAdvancedTools: PropTypes.bool,
  compact: PropTypes.bool
};

export default ViewerToolbar;
