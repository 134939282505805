import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { 
  Box,
  Typography,
  Button,
  CircularProgress,
  Paper,
  LinearProgress,
  Alert,
  IconButton,
  TextField
} from '@mui/material';
import { 
  Upload as UploadIcon,
  Close as CloseIcon, 
  InsertDriveFile as FileIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';
import { uploadDxf } from '../services/api';
import { useAppContext } from '../context/AppContext';

const UploadDxf = () => {
  const navigate = useNavigate();
  const { addDrawing, showNotification } = useAppContext();
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState(null);
  const [metadata, setMetadata] = useState({
    name: '',
    project: '',
    description: ''
  });

  // Функция для загрузки файла
  const handleUpload = async () => {
    if (!file) return;

    try {
      setUploading(true);
      setError(null);
      
      // Симулируем загрузку
      const simulateProgress = () => {
        setUploadProgress(0);
        const interval = setInterval(() => {
          setUploadProgress(prev => {
            if (prev >= 95) {
              clearInterval(interval);
              return 95;
            }
            return prev + 5;
          });
        }, 100);
        return interval;
      };
      
      const progressInterval = simulateProgress();
      
      // Загружаем файл через API
      const result = await uploadDxf(file, {
        name: metadata.name || file.name,
        project: metadata.project,
        description: metadata.description
      });
      
      clearInterval(progressInterval);
      setUploadProgress(100);
      
      // Показываем уведомление об успешной загрузке
      showNotification('File uploaded successfully!', 'success');
      
      // Добавляем новый чертеж в список и перенаправляем на страницу просмотра
      addDrawing({
        file_id: result.file_id,
        filename: file.name,
        upload_date: new Date().toISOString(),
        ...metadata
      });
      
      // Перенаправляем на просмотр загруженного файла
      setTimeout(() => {
        navigate(`/viewer/${result.file_id}`);
      }, 1000);
      
    } catch (err) {
      setError('Failed to upload file. Please try again.');
      console.error('Upload error:', err);
    } finally {
      setUploading(false);
    }
  };

  // Функция отмены загрузки
  const handleCancel = () => {
    setFile(null);
    setMetadata({
      name: '',
      project: '',
      description: ''
    });
    setError(null);
  };

  // Настройка dropzone для перетаскивания файлов
  const onDrop = useCallback(acceptedFiles => {
    // Берем только первый файл
    const selectedFile = acceptedFiles[0];
    
    // Проверяем, является ли файл DXF
    if (selectedFile && (selectedFile.name.toLowerCase().endsWith('.dxf') || 
        selectedFile.type === 'application/dxf' || 
        selectedFile.type === 'image/vnd.dxf')) {
      setFile(selectedFile);
      
      // Автоматически заполняем имя из имени файла
      setMetadata(prev => ({
        ...prev,
        name: selectedFile.name.replace(/\.[^/.]+$/, "") // Убираем расширение
      }));
      
      setError(null);
    } else {
      setError('Please select a valid DXF file.');
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/dxf': ['.dxf'],
      'image/vnd.dxf': ['.dxf']
    },
    maxFiles: 1
  });

  // Обработчики изменения полей метаданных
  const handleMetadataChange = (e) => {
    const { name, value } = e.target;
    setMetadata(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', p: 2 }}>
      {/* Заголовок */}
      <Typography variant="h4" gutterBottom align="center">
        Upload DXF File
      </Typography>
      
      {/* Сообщение об ошибке */}
      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 2 }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setError(null)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {error}
        </Alert>
      )}
      
      {/* Зона для перетаскивания, если файл еще не выбран */}
      {!file && (
        <Paper
          {...getRootProps()}
          sx={{
            p: 6,
            border: '2px dashed',
            borderColor: isDragActive ? 'primary.main' : 'grey.400',
            borderRadius: 2,
            backgroundColor: isDragActive ? 'rgba(25, 118, 210, 0.04)' : 'background.paper',
            textAlign: 'center',
            cursor: 'pointer',
            transition: 'all 0.3s',
            '&:hover': {
              borderColor: 'primary.main',
              backgroundColor: 'rgba(25, 118, 210, 0.04)'
            }
          }}
        >
          <input {...getInputProps()} />
          <UploadIcon sx={{ fontSize: 64, color: 'primary.main', mb: 2 }} />
          <Typography variant="h6" gutterBottom>
            {isDragActive ? 'Drop the file here' : 'Drag & Drop DXF file here'}
          </Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            or
          </Typography>
          <Button variant="contained" component="span">
            Browse Files
          </Button>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
            Only DXF files are supported
          </Typography>
        </Paper>
      )}
      
      {/* Предпросмотр выбранного файла и форма метаданных */}
      {file && (
        <Paper sx={{ p: 3, mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <FileIcon sx={{ fontSize: 40, mr: 2, color: 'primary.main' }} />
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle1">{file.name}</Typography>
              <Typography variant="body2" color="textSecondary">
                {(file.size / 1024).toFixed(2)} KB
              </Typography>
            </Box>
            <IconButton onClick={handleCancel} disabled={uploading}>
              <DeleteIcon />
            </IconButton>
          </Box>
          
          {/* Форма метаданных */}
          <Typography variant="h6" gutterBottom>
            File Information
          </Typography>
          
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={metadata.name}
            onChange={handleMetadataChange}
            margin="normal"
            variant="outlined"
          />
          
          <TextField
            fullWidth
            label="Project"
            name="project"
            value={metadata.project}
            onChange={handleMetadataChange}
            margin="normal"
            variant="outlined"
          />
          
          <TextField
            fullWidth
            label="Description"
            name="description"
            value={metadata.description}
            onChange={handleMetadataChange}
            margin="normal"
            variant="outlined"
            multiline
            rows={3}
          />
          
          {/* Прогресс загрузки */}
          {uploading && (
            <Box sx={{ mt: 2, mb: 1 }}>
              <LinearProgress variant="determinate" value={uploadProgress} />
              <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 1 }}>
                {uploadProgress}% Uploaded
              </Typography>
            </Box>
          )}
          
          {/* Кнопки действий */}
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button 
              variant="outlined" 
              onClick={handleCancel}
              disabled={uploading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              disabled={uploading}
              startIcon={uploading ? <CircularProgress size={20} /> : <UploadIcon />}
            >
              {uploading ? 'Uploading...' : 'Upload File'}
            </Button>
          </Box>
        </Paper>
      )}
      
      {/* Дополнительная информация */}
      <Paper sx={{ p: 3, mt: 3 }}>
        <Typography variant="h6" gutterBottom>
          Tips for DXF Files
        </Typography>
        <Typography variant="body2" paragraph>
          • Ensure your DXF file is saved in a compatible format (AutoCAD DXF R12/2000/2004/2007)
        </Typography>
        <Typography variant="body2" paragraph>
          • Clean up unnecessary layers and objects before uploading
        </Typography>
        <Typography variant="body2" paragraph>
          • Organize objects on different layers for easier management
        </Typography>
        <Typography variant="body2">
          • Maximum file size: 50MB
        </Typography>
      </Paper>
    </Box>
  );
};

export default UploadDxf;
