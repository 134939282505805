import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Container, 
  Box, 
  Drawer, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  CssBaseline, 
  ThemeProvider, 
  IconButton, 
  Divider, 
  Snackbar,
  Alert,
  Switch,
  FormControlLabel
} from '@mui/material';
import { 
  Menu as MenuIcon, 
  Home as HomeIcon, 
  Upload as UploadIcon, 
  List as ListIcon, 
  InsertChart as ChartIcon, 
  Settings as SettingsIcon,
  Brightness4 as DarkModeIcon,
  Brightness7 as LightModeIcon
} from '@mui/icons-material';
import { Link } from 'react-router-dom';

// Import components
import DxfViewer from './components/DxfViewer';
import UploadDxf from './components/UploadDxf';
import DrawingsList from './components/DrawingsList';
import Home from './pages/Home';
import { AppProvider, useAppContext } from './context/AppContext';

// Импортируем темы из файла theme.js
import { lightTheme, darkTheme } from './styles/theme';

// Mock components for development
const ReportsPage = () => (
  <Container>
    <Typography variant="h4" gutterBottom>Reports</Typography>
    <Typography variant="body1">This page is under development.</Typography>
  </Container>
);

const SettingsPage = () => (
  <Container>
    <Typography variant="h4" gutterBottom>Settings</Typography>
    <Typography variant="body1">This page is under development.</Typography>
  </Container>
);

// Main application layout with navigation
const AppLayout = ({ toggleDarkMode, darkMode }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { notification } = useAppContext();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawerWidth = 240;

  const menuItems = [
    { text: 'Главная', icon: <HomeIcon />, path: '/' },
    { text: 'Загрузить DXF', icon: <UploadIcon />, path: '/upload' },
    { text: 'Мои чертежи', icon: <ListIcon />, path: '/drawings' },
    { text: 'Отчеты', icon: <ChartIcon />, path: '/reports' },
    { text: 'Настройки', icon: <SettingsIcon />, path: '/settings' }
  ];

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component={Link} to="/" sx={{ color: 'white', textDecoration: 'none', flexGrow: 1 }}>
            DXF Viewer
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={darkMode}
                onChange={toggleDarkMode}
                color="default"
              />
            }
            label={darkMode ? <DarkModeIcon /> : <LightModeIcon />}
            sx={{ color: 'white', mr: 1 }}
          />
        </Toolbar>
      </AppBar>
      
      <Drawer
        variant="temporary"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            top: ['48px', '56px', '64px'],
            height: 'auto',
            bottom: 0,
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {menuItems.map((item) => (
              <ListItem 
                button 
                key={item.text} 
                component={Link} 
                to={item.path} 
                onClick={toggleDrawer}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <Box sx={{ p: 2 }}>
            <Typography variant="body2" color="textSecondary">
              Версия: 0.1.0
            </Typography>
          </Box>
        </Box>
      </Drawer>
      
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/upload" element={<UploadDxf />} />
          <Route path="/drawings" element={<DrawingsList />} />
          <Route path="/viewer/:fileId" element={<DxfViewer />} />
          <Route path="/reports" element={<ReportsPage />} />
          <Route path="/settings" element={<SettingsPage />} />
        </Routes>
      </Box>
      
      {/* Notification Snackbar */}
      {notification && (
        <Snackbar 
          open={!!notification} 
          autoHideDuration={5000} 
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert severity={notification.type || 'info'} sx={{ width: '100%' }}>
            {notification.message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

// Main App component wrapped with providers
function App() {
  const [darkMode, setDarkMode] = useState(false);

  // Переключение темы
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    // Сохраняем выбор в localStorage
    localStorage.setItem('darkMode', String(!darkMode));
  };

  // Загружаем сохраненный выбор темы при первом рендере
  useEffect(() => {
    const savedDarkMode = localStorage.getItem('darkMode') === 'true';
    setDarkMode(savedDarkMode);
  }, []);

  // Выбираем тему в зависимости от настройки
  const theme = darkMode ? darkTheme : lightTheme;
  
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppProvider>
        <AppLayout toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;
