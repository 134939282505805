/**
 * API service for interacting with the backend
 * Handles all HTTP requests to the DXF Viewer API
 */

import axios from 'axios';

// Create axios instance with baseURL and default headers
const api = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a response interceptor for error handling
api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    
    // Log errors to console for debugging
    console.error('API Error:', response?.data || error.message);
    
    // Rethrow the error to be handled by the component
    return Promise.reject(error);
  }
);

/**
 * Upload a DXF file
 * @param {File} file - The DXF file to upload
 * @param {Object} metadata - Optional metadata for the drawing
 * @returns {Promise<Object>} - Upload response with file_id
 */
export const uploadDxf = async (file, metadata = {}) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    
    if (metadata && Object.keys(metadata).length > 0) {
      formData.append('metadata', JSON.stringify(metadata));
    }
    
    const response = await api.post('/drawings/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error uploading DXF:', error);
    throw error;
  }
};

/**
 * Fetch a list of all available drawings with optional filtering
 * @param {Object} filters - Optional filters for the drawings list
 * @param {number} limit - Maximum number of drawings to return
 * @param {number} skip - Number of drawings to skip (for pagination)
 * @param {string} sortBy - Field to sort by
 * @param {number} sortOrder - Sort order (1 for ascending, -1 for descending)
 * @returns {Promise<Array>} - List of drawings
 */
export const fetchDrawings = async (filters = {}, limit = 50, skip = 0, sortBy = 'upload_date', sortOrder = -1) => {
  try {
    // Build query parameters
    const params = new URLSearchParams();
    
    // Add filters if provided
    if (filters.project) params.append('project', filters.project);
    if (filters.status) params.append('status', filters.status);
    if (filters.dateFrom) params.append('date_from', filters.dateFrom);
    if (filters.dateTo) params.append('date_to', filters.dateTo);
    
    // Add pagination and sorting
    params.append('limit', limit);
    params.append('skip', skip);
    params.append('sort_by', sortBy);
    params.append('sort_order', sortOrder);
    
    const response = await api.get(`/drawings?${params.toString()}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching drawings list:', error);
    throw error;
  }
};

/**
 * Fetch a specific drawing by ID
 * @param {string} fileId - The ID of the drawing to fetch
 * @param {boolean} includeEntities - Whether to include entities in the response
 * @param {boolean} includeMetrics - Whether to include metrics in the response
 * @returns {Promise<Object>} - Drawing data including entities
 */
export const fetchDrawing = async (fileId, includeEntities = true, includeMetrics = true) => {
  try {
    const params = new URLSearchParams();
    params.append('include_entities', includeEntities);
    params.append('include_metrics', includeMetrics);
    
    const response = await api.get(`/drawings/${fileId}?${params.toString()}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Update an entity's status and color
 * @param {string} fileId - The ID of the drawing
 * @param {string} entityId - The ID of the entity to update
 * @param {string} status - New status ('not_started', 'in_progress', 'delayed', 'completed')
 * @param {string} color - New color (hex format, e.g. '#00FF00')
 * @returns {Promise<Object>} - Update result with metrics
 */
export const updateEntityStatus = async (fileId, entityId, status, color) => {
  try {
    const response = await api.patch(`/drawings/${fileId}/entities`, {
      id: entityId,
      status,
      color,
    });
    
    return response.data;
  } catch (error) {
    console.error(`Error updating entity ${entityId}:`, error);
    throw error;
  }
};

/**
 * Update status for multiple elements at once
 * @param {string} fileId - The ID of the drawing
 * @param {Object} data - The update data containing element_ids, status, and color
 * @returns {Promise<Object>} - Update result with metrics
 */
export const updateElementsStatus = async (fileId, data) => {
  try {
    const response = await api.patch(`/drawings/${fileId}/elements/status`, data);
    return response.data;
  } catch (error) {
    console.error(`Error updating multiple elements in ${fileId}:`, error);
    throw error;
  }
};

/**
 * Fetch metrics for a drawing
 * @param {string} fileId - The ID of the drawing
 * @param {boolean} recalculate - Whether to recalculate metrics
 * @returns {Promise<Object>} - Metrics data (progress, costs, etc.)
 */
export const fetchMetrics = async (fileId, recalculate = false) => {
  try {
    const params = new URLSearchParams();
    params.append('recalculate', recalculate);
    
    const response = await api.get(`/drawings/${fileId}/metrics?${params.toString()}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching metrics for drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Fetch layer information for a drawing
 * @param {string} fileId - The ID of the drawing
 * @returns {Promise<Array>} - List of layers with metrics
 */
export const fetchLayers = async (fileId) => {
  try {
    const response = await api.get(`/drawings/${fileId}/layers`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching layers for drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Generate a report for a drawing
 * @param {string} fileId - The ID of the drawing
 * @param {string} format - Report format ('json', 'csv')
 * @param {boolean} includeEntities - Whether to include entities in the report
 * @returns {Promise<Object|Blob>} - Report data or file
 */
export const generateReport = async (fileId, format = 'json', includeEntities = false) => {
  try {
    const params = new URLSearchParams();
    params.append('format', format);
    params.append('include_entities', includeEntities);
    
    const response = await api.get(`/drawings/${fileId}/report?${params.toString()}`, {
      responseType: format === 'json' ? 'json' : 'blob',
    });
    
    return response.data;
  } catch (error) {
    console.error(`Error generating report for drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Check server health
 * @returns {Promise<Object>} - Health status
 */
export const checkHealth = async () => {
  try {
    const response = await api.get('/health');
    return response.data;
  } catch (error) {
    console.error('Health check failed:', error);
    throw error;
  }
};

/**
 * Download the original DXF file
 * @param {string} fileId - The ID of the drawing
 * @returns {Promise<Blob>} - DXF file as a Blob
 */
export const downloadOriginalDxf = async (fileId) => {
  try {
    const response = await api.get(`/drawings/${fileId}/download`, {
      responseType: 'blob',
    });
    
    return response.data;
  } catch (error) {
    console.error(`Error downloading DXF file ${fileId}:`, error);
    throw error;
  }
};

/**
 * Save current state as a new version
 * @param {string} fileId - The ID of the drawing
 * @param {string} versionName - Name for the new version
 * @param {string} description - Optional description for the version
 * @returns {Promise<Object>} - Version information
 */
export const saveVersion = async (fileId, versionName, description = '') => {
  try {
    const response = await api.post(`/drawings/${fileId}/versions`, {
      name: versionName,
      description: description
    });
    
    return response.data;
  } catch (error) {
    console.error(`Error saving version for drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Get list of versions for a drawing
 * @param {string} fileId - The ID of the drawing
 * @returns {Promise<Array>} - List of versions
 */
export const getVersions = async (fileId) => {
  try {
    const response = await api.get(`/drawings/${fileId}/versions`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching versions for drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Perform a bulk status update for entities matching filter criteria
 * @param {string} fileId - The ID of the drawing
 * @param {Object} filter - Filter criteria (e.g., layer, type)
 * @param {string} status - New status to apply
 * @returns {Promise<Object>} - Update result with metrics
 */
export const bulkUpdateStatus = async (fileId, filter, status) => {
  try {
    const response = await api.post(`/drawings/${fileId}/bulk-update`, {
      filter,
      status,
      color: STATUS_COLORS[status] // Use the standard status colors
    });
    
    return response.data;
  } catch (error) {
    console.error(`Error performing bulk update for drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Calculate metrics by entity type
 * @param {string} fileId - The ID of the drawing
 * @returns {Promise<Object>} - Metrics grouped by entity type
 */
export const fetchMetricsByType = async (fileId) => {
  try {
    const response = await api.get(`/drawings/${fileId}/metrics-by-type`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching metrics by type for drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Fetch timeline projections based on current progress
 * @param {string} fileId - The ID of the drawing
 * @param {Date} startDate - Project start date
 * @param {Date} plannedEndDate - Planned completion date
 * @returns {Promise<Object>} - Timeline projection data
 */
export const fetchTimelineProjections = async (fileId, startDate, plannedEndDate) => {
  try {
    const params = new URLSearchParams();
    if (startDate) params.append('start_date', startDate.toISOString());
    if (plannedEndDate) params.append('planned_end_date', plannedEndDate.toISOString());
    
    const response = await api.get(`/drawings/${fileId}/timeline?${params.toString()}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching timeline projections for drawing ${fileId}:`, error);
    throw error;
  }
};

/**
 * Common status colors for consistent UI
 */
export const STATUS_COLORS = {
  not_started: '#808080', // Grey
  in_progress: '#FFA500', // Orange
  delayed: '#FF0000',     // Red
  completed: '#00FF00'    // Green
};

/**
 * Common status labels for consistent UI
 */
export const STATUS_LABELS = {
  not_started: 'Not Started',
  in_progress: 'In Progress',
  delayed: 'Delayed',
  completed: 'Completed'
};

// Export the API instance in case it's needed for custom requests
export default api;
