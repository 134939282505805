import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// Если вы хотите начать измерять производительность в вашем приложении, передайте функцию
// для регистрации результатов (например: reportWebVitals(console.log))
// или отправляйте их в аналитическую конечную точку. Узнайте больше: https://bit.ly/CRA-vitals
reportWebVitals();

// Создаем reportWebVitals.js, если его нет
if (typeof reportWebVitals === 'undefined') {
  window.reportWebVitals = () => {};
}
